import React, {  Fragment } from 'react';
import {  Switch, Route ,withRouter} from 'react-router-dom';
import Header1 from './layout/header/header1';
import Footer from './layout/footer/footer1';


import './App.css';
import './Vendor.js';

// Home Pages
import Index from './pages/Index/index1';

// Shop Pages

import PageNotFound from './pages/components/404';
import Scrolltop from './layout/back-to-top';
import  {getProducts} from './actions';
import { connect } from 'react-redux';
import PrivacyPolicy from './pages/common/privacy-policy';
import TermsConditions from './pages/common/terms-and-conditions';

// FAQ



//paginas activas
import AppsWeb from './pages/Servicios/AppsWeb';
import AppsMovil from './pages/Servicios/AppsMovil';
import Crossplatform from "./pages/Servicios/Crossplatform";
import DevelopmentPlans from "./pages/Servicios/DevelopmentPlans";
import AboutUs from "./pages/AboutPoint/AboutUs";
import CaseStudies from './pages/components/casestudie/CaseStudies';
import Blog from './pages/blog/Blog';
import Contacto from './pages/contactus/Contacto';
import Header3 from "./layout/header/header3";
import Jose from "./pages/tarjeta/Jose/Jose";
import Carlos from "./pages/tarjeta/Carlos/Carlos";


//Blogs
import BlogHeart from "./pages/blog/blogs/BlogHeart";
import BlogCreatingWebsite from "./pages/blog/blogs/BlogCreatingWebsite";
import BlogScrum from "./pages/blog/blogs/BlogScrum";

//Case Studies

import JoyCityCaseStudy from "./pages/components/casestudie/casestudies/JoyCityCaseStudy";
import HoneyPotAgencyCaseStudy from "./pages/components/casestudie/casestudies/HoneyPotAgengyCaseStudy";
import UncoverIndexCaseStudy from "./pages/components/casestudie/casestudies/HoneyPotAgengyCaseStudy";


class App extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount()
  {
    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "ES");
    }
    this.props.getProducts();
  }

  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/comming-soon' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false;
  }

  setHeader(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/AppsMovil' ? true :  false;
  }


  render() {
    const { location } = this.props;
    return (
      <Fragment >
         {
             this.getUrl(location.pathname) ?
                <Switch>
                    <Route path="/comming-soon" component={CommingSoon} />
                    <Route path="/page-not-found" component={PageNotFound} />
                </Switch>
              :
        <div className="page-wrapper">
          {this.setHeader(location.pathname) ?<Header3 /> : <Header1 />}
        
          <Switch>
            {/* Paginas Activas */}
            <Route exact path="/" component={Index} />
            <Route exact path="/Apps" component={AppsWeb} />
            <Route exact path="/Web" component={Crossplatform}/>
            <Route exact path="/Other" component={DevelopmentPlans}/>
            <Route exact path="/about-us" component={AboutUs}/>
            <Route exact path="/CaseStudies" component={CaseStudies}/>
            <Route exact path="/Contacto" component={Contacto}/>

            {/* blogs */}
            <Route exact path="/blog/How-is-your-website-HEART" component={BlogHeart}/>
            <Route exact path="/blog/How-to-create-the-perfect-website-for-your-costumers" component={BlogCreatingWebsite}/>
            <Route exact path="/blog/Ready-set-SCRUM" component={BlogScrum}/>

            {/* Tarjetas */}
            <Route exact path="/JoseVisoso" component={Jose}/>
            <Route exact path="/CarlosLara" component={Carlos}/>


            {/* case studies */}

            <Route exact path="/case-studies/Joy-City" component={JoyCityCaseStudy}/>
            <Route exact path="/case-studies/Honey-Pot-Agency" component={HoneyPotAgencyCaseStudy}/>
            <Route exact path="/case-studies/Uncover-Index" component={UncoverIndexCaseStudy}/>

            {/* Case Studie Pages */}


            {/* Contact Pages */}
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsConditions} />
          </Switch>

          <Footer />
          <Scrolltop /> 
        </div>
         }
      </Fragment>
    );
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    getProducts: () => {
      dispatch(getProducts());
    }
  };
};


export default connect(AppMapStateToProps,AppMapDispatchToProps)(withRouter(App))

