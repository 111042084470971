import React, { Component } from 'react';
import Herosection3 from '../../widgets/herosecation/Herosection3';
import AboutBox2 from '../../widgets/about/AboutBox2';
import FeatureBox4 from '../../widgets/featurebox/FeatureBox4';

import {CrossplatformT} from "../../text/Servicios/Crossplatform/CrossplatformT";
import { connect } from "react-redux";
import Cuestionario from "../../widgets/cuestionario";
import {SoftwareT} from "../../text/Servicios/Software/SoftwareT";
import {PricingTableT} from "../../text/Servicios/Crossplatform/PricingTableT";

import PriceTable1 from "../../widgets/price/PriceTable1";

class Crossplatform extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.insideText();
  }

  // On Scroll Inside Text Move
  insideText() {
    var e, i = $(window).height(),
    n = i / 2;
    $(document).scroll(function () {
      e = $(window).scrollTop(), $(".inside-text").each(function () {
        var i = $(this),
          o = i.parent("section"),
          s = o.offset().top;
        i.css({
          top: -(s - e) + n + "px"
        })
      })
    })
  };
  render() {
    return (
      <div>
        {/*hero section start*/}
        <Herosection3 />
        {/*hero section end*/}
        {/*body content start*/}
        <div id="main-page" className="page-content">
          {/*about start*/}
          <section className="pb-0 z-index-1 pos-r o-hidden">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-md-10">
                  <h4 className="text-black font-w-5 line-h-2">{CrossplatformT[localStorage.getItem("language")].p}  <span className="text-theme">{CrossplatformT[localStorage.getItem("language")].point}</span> {CrossplatformT[localStorage.getItem("language")].p1} </h4>
                </div>
              </div>

            </div>
          </section>
          {/*about end*/}


          {/*pricing table start*/}
          <section className="pos-r">
            <div className="dot-bg">
              <img className="img-fluid rotateme" src={require(`../../assets/images/pattern/04.png`)} alt="" />
            </div>
            <div className="container">
              <div className="row align-items-end mb-5">
                <div className="col-lg-6 col-md-12 text-lg-right order-lg-1">
                  <div className="section-title mb-lg-0 mb-2">
                    <h2 className="title mb-0">{PricingTableT[localStorage.getItem("language")].pricingT}<span> {PricingTableT[localStorage.getItem("language")].pricingH}</span></h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="lead mb-0">{PricingTableT[localStorage.getItem("language")].pricingP}</p>
                </div>
              </div>
              <PriceTable1 />
            </div>
          </section>
          {/*pricing table end*/}




          {/*
          <section className="custom-mt-10 pt-0">
            <div className="container">
              <div className="row">
                <div className="col">
                  <FeatureBox4 logoitems={3} />
                </div>
              </div>
            </div>
          </section>
          */}
          {/*service end*/}
          <Cuestionario />


        </div>
        {/*body content end*/}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Crossplatform);





