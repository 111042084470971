import React, { Component } from 'react';
import Tilt from 'react-tilt';

class Clientbox1 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="grid">
                <Tilt className="cases-item style-3 Tilt" options={{ perspective:300,speed:500,max : 15,scale:1 }}>
                      <div>
                          <a href="https://www.genommalab.com/en/">
                            <img className="img-fluid clientimg" src={require(`../../assets/images/client/genommalogo.png`)} alt=""  />
                          </a>
                        </div>

                </Tilt>

                <Tilt className="cases-item style-3 Tilt" options={{ perspective:300,speed:500,max : 15,scale:1 }}>
                    <div>
                        <a href="https://rfglobalunitrade.org/">
                            <img className="img-fluid clientimg" src={require(`../../assets/images/client/globalUnitrade.png`)} alt="" />
                        </a>
                    </div>
                </Tilt>

                <Tilt className="cases-item style-3 Tilt" options={{ perspective:300,speed:500,max : 15,scale:1 }}>
                    <div>
                         <a href="https://www.cemefi.org/">
                            <img className="img-fluid clientimg" src={require(`../../assets/images/client/cemefi.png`)} alt="" />
                        </a>
                    </div>
                </Tilt>

                <Tilt className="cases-item style-3 Tilt" options={{ perspective:300,speed:500,max : 15,scale:1 }}>
                    <div>
                        <a href="https://joy-city-291022.an.r.appspot.com/">
                            <img className="img-fluid clientimg" src={require(`../../assets/images/client/JoyCity.jpg`)} alt="" />
                        </a>
                    </div>
                </Tilt>

                <Tilt className="cases-item style-3 Tilt" options={{ perspective:300,speed:500,max : 15,scale:1 }}>
                    <div>
                        <a href="https://www.ecointell.com.mx/">
                            <img className="img-fluid clientimg" src={require(`../../assets/images/client/eco.jpg`)} alt="" />
                        </a>
                    </div>
                </Tilt>


                <Tilt className="cases-item style-3 Tilt" options={{ perspective:300,speed:500,max : 15,scale:1 }}>
                    <div>
                        <a href="https://agoraplus.mx/">
                            <img className="img-fluid clientimg " src={require(`../../assets/images/client/logoAgora.png`)} alt="" />
                        </a>
                    </div>
                </Tilt>

            </div>
        );
    }
}

export default Clientbox1;
