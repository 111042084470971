import React from "react"
import { Link } from "react-router-dom"
export const FeatureText5 = {
    EN: {
        title:"What you win by",
        highlight:"choosing Point.",
        win1:"Experienced Team",
        p1:"We have provided digital solutions to different companies, due to this knowledge we are able to offer you a complete service backed by the experience we have obtained over the years.",
        win2:"Quality Service",
        p2:"By having a multidisciplinary team of experts, we make sure to maintain our quality standards in all areas of our projects. We only offer the highest quality products and we always use best practices.",
        win3:"24/7 Support",
        p3:"Clients are our priority, when you choose to work with us, our time is yours. We’ll be available for you during the entire process.",
    },
    ES: {
        title:"Lo que ganas al",
        highlight:"Escoger a Point.",
        win1:"Equipo con Experiencia",
        p1:"Hemos otorgado soluciones digitales a diferentes empresas, debido a este conocimiento somos capaces de ofrecerte un servicio completo respaldado por la experiencia que obtuvimos a través de los años.",
        win2:"Servicio de Calidad",
        p2:"Al tener un equipo de expertos multidiciplinario, nos aseguramos de manener nuestros estándares de calidad en todas las áreas de nuestros proyectos. Sólo ofrecemos productos de la más alta calidad y utilizamos siempre las mejores prácticas.",
        win3:"Soporte 24/7",
        p3:"Nuestra prioridad son nuestros clientes, al trabajar con Point nuestro tiempo es tuyo, estaremos presentes y disponibles para ti durante todo el proceso.",
    },
}