import React from "react"
import { Link } from "react-router-dom"
export const SkillBoxT = {
    EN: {
        s1:"UX/UI Design",
        s2:"Bots",
        s3:"Dashboards",
        s4:"IOT",
    },
    ES: {
        s1:"Diseño UX/UI",
        s2:"Bots",
        s3:"Dashboards",
        s4:"IOT",
    },
}