import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Particles from 'react-particles-js';
import ReactTextTransition, { presets } from "react-text-transition";


import { connect } from "react-redux";
import  {HeroSectionText} from "../../text/Index/HeroSectionText";

const particleOpt = {
    "particles": {
        "number": {
            "value": 50
        },
        "size": {
            "value": 3
        }
    },
    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "repulse"
            }
        }
    }
}

const texts = ["Web", "Mobile", "Desktop"];


class Herosection1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "web",
            textIndex: 0,
        };
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                textIndex: this.state.textIndex + 1,
            });
        }, 4000);

    }

    render() {
        return (
            <section className="banner pos-r fullscreen-banner dark-bg p-0" style={{background:"linear-gradient(#9500ff, #3700ff)"}}>
                <Particles className="particle" params={particleOpt}/>
                <div className="align-center pt-0 b-pl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-11 offset-1">
                                <div className="bnr-title text-lg-left">{HeroSectionText[localStorage.getItem("language")].HeroBnr}</div>
                            </div>
                            <div className="col-lg-12 offset-1">

                                <div  className="bnr-title text-lg-left letter-space-1">
                                    <ReactTextTransition
                                        text={texts[this.state.textIndex % texts.length]}
                                        springConfig={presets.gentle}
                                        style={{ margin: "0 14px 0 0", color: "#fff",opacity:"0.5"}}
                                        inline
                                    />
                                    Apps
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="hero-left h-100 d-flex align-items-center justify-content-center">
                    <div className="hero-social text-center">
                        <ul className="list-unstyled">
                            <li>
                                <a href="https://www.facebook.com/Pointech.consulting" target="_blank"> <i className="fab fa-facebook-f"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/pointech.dev/" target="_blank"> <i className="fab fa-instagram"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/69308202/" target="_blank"> <i className="fab fa-linkedin-in"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Link to="#Herosection1" className="scroll-down-arrow"/>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(Herosection1);