import React from "react"
import { Link } from "react-router-dom"
export const WebAppsHeroT = {
    EN: {
        Subtitle: "Custom made Apps",
        Title: "We develop web and mobile applications",
        Highlight: "that are intuitive and easy to use",
        p: "We develop user-centric applications that take into account how people will actually interact with their digital ecosystem. We optimize processes, design and interfaces so that our clients can provide an excellent experience to their users.",
        p2: "We iterate on this process and correct our model based on actual user feedback, allowing you to stay ahead of the competition at every turn.",


    },
    ES: {
        Subtitle:"Apps hechas a la medida",
        Title:"Desarrollamos aplicaciones web y móviles",
        Highlight:"que son intuitivas y fáciles de usar",
        p:"Desarrollamos aplicaciones centradas en el usuario que toman en cuenta la forma en la que las personas interactuarán realmente con su ecosistema digital. Optimizamos procesos, diseño e interfaces para que nuestros clientes puedan brindar una excelente experiencia a sus usuarios.",
        p2:"Repetimos este proceso y corregimos nuestro modelo en función de los comentarios reales de los usuarios, lo que le permite mantenerse por delante de la competencia en todo momento.",


    },
}