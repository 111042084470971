import React, { Component } from 'react';
import CountUp from 'react-countup';

import { connect } from "react-redux";
import  {AboutUsText} from "../../text/Index/AboutUsText";


class AboutBox extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row align-items-center">
                <div className="col-lg-6 col-12">
                    <img className="img-fluid w-100 box-shadow" src={require(`../../assets/images/index/who_are_we/about_1.jpg`)} alt="" />
                    <div className="row mt-4">
                        <div className="col-sm-6 order-sm-1">
                            <img className="img-fluid w-100 box-shadow" src={require(`../../assets/images/index/who_are_we/about_2.jpg`)} alt="" />
                        </div>
                        <div className="col-sm-6 mt-4 mt-sm-0">
                            <div className="featured-item active style-3 p-4 box-shadow">
                                <h3 className=".hint text-white mb-0 font-w-5 ">{AboutUsText[localStorage.getItem("language")].BoxCont} </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-12 mt-5 mt-lg-0 mr-auto">
                    <div className="section-title">
                        <h6>{AboutUsText[localStorage.getItem("language")].Subtitle}</h6>
                        <h2 className="title">{AboutUsText[localStorage.getItem("language")].Title} <span>{AboutUsText[localStorage.getItem("language")].Highlight} </span></h2>
                        <p className="lead mb-0">{AboutUsText[localStorage.getItem("language")].p}</p>
                        <br />
                        <p className="lead mb-0">{AboutUsText[localStorage.getItem("language")].p2}</p>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(AboutBox);