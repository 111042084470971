import React from "react"
import { Link } from "react-router-dom"
export const SoftwareT = {
    EN: {
        highlightabout:"We make it Simple to",
        about:"Take advantage of a team of experts",
        pricingT:"Choose Your Best",
        pricingH:"Pricing Plan for you",
        pricingP:"Determine the specialization of our 10 hour plans required to optimize the development of your project.",
        button:"Get a Quote",
        marcas:"Companies that trust us",
        pmarcas:"We've worked with companies raging in size and experience.",

    },
    ES: {
        highlightabout:"Simplificamos el aprovechamiento",
        about:"De un equipo de expertos",
        pricingT:"Elige el mejor ",
        pricingH:"Plan de Precios para ti",
        pricingP:"Determina la especialización de nuestros planes de 10 horas que requieres para optimizar el desarrollo de tu proyecto.",
        button:"Cotiza ahora",
        marcas:"Empresas que confían en nosotros",
        pmarcas:"Hemos trabajado con empresas de distintos tamaños y trayectorias. "
    },
}