/**
 *  Main Menu Json
 *
 *
 */
export default {
    ES: [
        {
            "menu_title": "Nosotros",
            "type": "",
            "path": "/about-us",
            "icon": "AboutUs",
        },
        {
            "menu_title": "Servicios",
            "type": "subMenu",
            "path": "",
            "icon": "AboutUs",
            "child_routes": [

                {
                    "path": "/Apps",
                    "menu_title": "Desarrollo de Aplicaciones",
                    "icon": "arrow_right_alt",
                    "child_routes": null
                },
                {
                    "path": "/Web",
                    "menu_title": "Desarrollo Web",
                    "icon": "arrow_right_alt",
                    "child_routes": null
                },
                {
                    "path": "/Other",
                    "menu_title": "Otros Ecosistemas",
                    "icon": "arrow_right_alt",
                    "child_routes": null
                },
            ]
        },
        {
            "menu_title": "Casos de estudio",
            "type": "",
            "path": "/CaseStudies",
            "icon": "Casos de Estudio",
        },

        {
            "menu_title": "Contacto",
            "type": "",
            "path": "/Contacto",
            "icon": "contact",
        },
    ],

    EN: [

        {
            "menu_title": "About Us",
            "type": "",
            "path": "/about-us",
            "icon": "AboutUs",
        },
        {
            "menu_title": "Services",
            "type": "subMenu",
            "path": "/servicios",
            "icon": "AboutUs",
            "child_routes": [

                {
                    "path": "/Apps",
                    "menu_title": "App development",
                    "icon": "arrow_right_alt",
                    "child_routes": null
                },
                {
                    "path": "/Web",
                    "menu_title": "Web development",
                    "icon": "arrow_right_alt",
                    "child_routes": null
                },
                {
                    "path": "/Other",
                    "menu_title": "Other Ecosystems",
                    "icon": "arrow_right_alt",
                    "child_routes": null
                },
            ]
        },
        {
            "menu_title": "Case Studies",
            "type": "",
            "path": "/CaseStudies",
            "icon": "Casos de Estudio",
        },

        {
            "menu_title": "Contact Us",
            "type": "",
            "path": "/Contacto",
            "icon": "contact",
        },
    ],


}