import React, { Component } from 'react';
import {CrossplatformT} from "../../text/Servicios/Crossplatform/CrossplatformT";
import { connect } from "react-redux";

class AboutBox2 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row mt-5">
                <div className="col-lg-6 col-12 ml-auto pl-lg-5 order-lg-1">
                    <div className="section-title mb-4">
                        <h6> Sobre Nosotros</h6>
                        <h2 className="title">{CrossplatformT[localStorage.getItem("language")].AboutTitle}</h2>
                    </div>
                    <ul className="list-unstyled list-icon">
                        <li className="mb-4"> <i className="fas fa-check" /> {CrossplatformT[localStorage.getItem("language")].win1}</li>
                        <li className="mb-4"> <i className="fas fa-check" /> {CrossplatformT[localStorage.getItem("language")].win2}</li>
                        <li className="mb-4 mb-sm-0"> <i className="fas fa-check" /> {CrossplatformT[localStorage.getItem("language")].win3} </li>
                        <li> <i className="fas fa-check" /> {CrossplatformT[localStorage.getItem("language")].win4}</li>
                    </ul>
                    <p className="mt-4">{CrossplatformT[localStorage.getItem("language")].desc}</p>
                </div>
                <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                    <img className="img-fluid w-100" src={require(`../../assets/images/about/01.jpg`)} alt="" />
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(AboutBox2);