import React, { Component } from 'react';
import Pageheading from '../../../widgets/Pageheading';

class BlogCreatingWebsite extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                {/*page title start*/}
                <Pageheading foldername={"Blog"} title={"How To Create The Perfect Website For Your Customers"} />
                {/*page title end*/}
                {/*body content start*/}
                <div id="main-page" className="page-content">
                    {/*blog start*/}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="post">
                                        <div className="post-image">
                                            <img className="img-fluid w-100" src={require(`../../../assets/images/blog/blog-creatingwebsite.jpg`)} alt="" />
                                        </div>
                                        <div className="post-desc pl-0">
                                            <div className="post-meta">
                                                <ul className="list-inline">

                                                    <li>April 08, 2021</li>
                                                </ul>
                                            </div>
                                            <div className="post-title">
                                                <h2>How to Create the Perfect Website
                                                    for Your Costumers</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <p className="ml-5 text-black">We all want the best possible website and we believe we know what it should look like, but let me let you in on a small secret: not even big tech companies like Facebook or Google are 100% sure about how to make the perfect website.</p>

                                            {/*IMAGEN*/}

                                            <p className="ml-5 text-black">So how were they able to create their amazing websites? Testing!</p>

                                            <p className="ml-5 text-black">Dont get me wrong designers are important, very important as they help skip the initial guessing proses and have the best insights on what you should test, but as to have the best possible website you need to test.</p>
                                            <p className="ml-5 text-black">Which photo works best? Where should the contact form be? What should my description be? What should my buttons say? All these questions may seem arbitrary and like they don’t even deserve a second thought, but they might be the most important to your business’ online success. The location of a contact form can improve (or worsen) your conversion rate by up to 50%. Your images and background may be the difference between a minimal and massive bounce rate. This is what testing is for - to know what elements of your website work best and where they should be placed.</p>
                                            <p className="ml-5 text-black">So where do we start? This is our method:</p>
                                            <p className= "ml-5 text-black"> 1. First, we come up with different versions of the same element in the website to test.</p>
                                            <p className= "ml-5 text-black"> 2. Then, we show them to different costumers - anyone from friends and family to people we reach through ads.</p>
                                            <p className= "ml-5 text-black"> 3. We then define and measure the KPIs that are important to us - engagement, conversions, bounce rate, clicks or any other metric we want to improve.</p>
                                            <p className= "ml-5 text-black"> 4. We A/B test two or more varialbles and find the best option - we then make that element live and available to all the users.</p>
                                            <p className= "ml-5 text-black"> 5. We start again. And again. And again.</p>

                                            <p className= "ml-5 text-black"> There’s no such thing as the perfect website but this method allows us to come as close as possible to the ideal set up, helping drive growth and all other key metrics.</p>
                                            <p className= "ml-5 text-black"> Sounds like too much work? Maybe it is, but at Pointech we offer this service to allow you to focus on your business, while we deal with the technology. Check out our services page to learn more.

                                            </p>

                                        </div>
                                    </div>
                                        <br/>

                                    {/*Other Blogs Start*/}
                                    {/* <div className="p-4 my-5 box-shadow" style={{backgroundColor: 'rgba(244,77,133,0.050)' }} >
                                        <RelatedBlog logoitems={2} />
                                    </div>*/}
                                    {/*Other Blogs End*/}

                                </div>
                            </div>
                        </div>
                    </section>
                    {/*blog end*/}
                </div>
                {/*body content end*/}
            </div>
        );
    }
}

export default BlogCreatingWebsite;