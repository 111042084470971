import React, {Component} from 'react';
import navLinks from "../../text/Menu/Menu";
import {Link} from 'react-router-dom';
import Contact from "../../text/Menu/Contact";
import {
    Collapse,
    Navbar,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from 'reactstrap';

import LanguageToggle from "../../widgets/Language/LanguageToggle";
import {connect} from "react-redux";

class Header1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            visible: false,
            loader: true,
            isCartview: false,
        }
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleClick(event) {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                isCartview: !this.state.isCartview
            });
        }
    }

    handleScroll() {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            this.setState({
                visible: true
            });
        } else {
            this.setState({
                visible: false
            });
        }
    }

    Viewcart() {
        this.setState({
            isCartview: !this.state.isCartview
        });
    }

    render() {
        const {visible, isCartview} = this.state;
        if (this.state.loader == true) {
            setTimeout(function () {
                this.setState({loader: false});
            }.bind(this), 0);
        }
        return (
            <header id="site-header" className="header">
                {(this.state.loader == false) ?
                    <div id="header-wrap" className={`${(visible) ? "fixed-header " : ""}`}>
                        <div className="container-fluid pl-lg-0 pr-lg-5">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* Navbar */}
                                    <Navbar className="navbar-expand-lg">
                                        <Link className="navbar-brand logo" to="/">
                                            <img className="img-fluid" src={require(`../../assets/images/logo.png`)}
                                                 alt=""/>
                                        </Link>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                                data-target="#navbarNavDropdown" aria-expanded="false"
                                                aria-label="Toggle navigation" onClick={this.toggle}><span/>
                                            <span/>
                                            <span/>
                                        </button>
                                        <Collapse isOpen={this.state.isOpen} className="navbar-collapse" navbar>
                                            <Nav className="ml-auto mr-auto" navbar>
                                                {navLinks[localStorage.getItem("language")].map((navLink, index) => (
                                                    (navLink.type && navLink.type === 'subMenu') ?
                                                        <UncontrolledDropdown nav inNavbar key={index}>
                                                            <DropdownToggle nav caret>
                                                                {navLink.menu_title}
                                                            </DropdownToggle>
                                                            <DropdownMenu id={`submenu_${index}`}>
                                                                {navLink.child_routes && navLink.child_routes.map((subNavLink, keys) => (
                                                                    (subNavLink.type && subNavLink.type === 'childsubMenu') ?
                                                                        <UncontrolledDropdown inNavbar
                                                                                              className="dropdown-submenu"
                                                                                              key={keys}>
                                                                            <DropdownToggle tag="a" caret>
                                                                                {subNavLink.menu_title}
                                                                            </DropdownToggle>
                                                                            <DropdownMenu id={`childsubmenu_${keys}`}>
                                                                                {subNavLink.child_routes && subNavLink.child_routes.map((ChildsubNavLink, i) =>
                                                                                    <DropdownItem key={i} tag={Link}
                                                                                                  to={ChildsubNavLink.path}
                                                                                                  onClick={this.handleClick.bind(this)}>{ChildsubNavLink.menu_title}
                                                                                    </DropdownItem>
                                                                                )}
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                        :
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                                                                <DropdownItem key={keys} tag={Link}
                                                                                              to={subNavLink.path}>{subNavLink.menu_title}
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                ))}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        :
                                                        <NavItem>
                                                            <Link key={index} className="nav-link"
                                                                  to={navLink.path}> {navLink.menu_title}</Link>
                                                        </NavItem>
                                                ))}
                                            </Nav>
                                        </Collapse>
                                        <div className="right-nav align-items-center d-flex justify-content-end">
                                            <div className="btn btn-sm-ui btn-outline-light-ui ml-3 mr-lg-5">
                                                    <LanguageToggle />
                                            </div>

                                            <Link className="btn btn-sm btn-outline-light ml-3 mr-lg-5"
                                                  to={Contact[localStorage.getItem("language")].path}>{Contact[localStorage.getItem("language")].menu_title}</Link>
                                        </div>
                                    </Navbar>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div id="ht-preloader">
                        <div className="clear-loader">
                            <div className="loaderBall"></div>
                        </div>
                    </div>
                }
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {language: state.language};
};

export default connect(mapStateToProps)(Header1);
