import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-tilt';


class CaseStudieBox extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row">


                <div className="col-md-6 col-lg-4">

                     <Tilt className="cases-item style-3 Tilt" options={{ perspective:1000,speed:500,max : 15,scale:1 }}  >
                        <div className="cases-images">
                            <img className="img-fluid" src={require(`../../assets/images/cases/JoyCityCaseStudy.jpg`)} alt="" />
                        </div>
                        <div className="cases-desc">
                            <div> <span>UX/UI Design</span>
                                <h3 className = "text-white">Joy City</h3>
                            </div>
                        </div>
                    </Tilt>

                </div>


                <div className="col-md-6 col-lg-4 mt-5 mt-md-0">

                     <Tilt className="cases-item style-3 Tilt" options={{ perspective:1000,speed:500,max : 15,scale:1 }}  >
                        <div className="cases-images">

                            <img className="img-fluid" src={require(`../../assets/images/cases/HoneyPotCaseStudy.jpg`)} alt=""/>

                        </div>
                        <div className="cases-desc">
                            <div> <span>Web Design</span>
                                <h3 className = "text-white">Honey Pot Agency</h3>
                            </div>
                        </div>
                    </Tilt>

                </div>


                <div className="col-md-6 col-lg-4 mt-5 mt-lg-0">

                     <Tilt className="cases-item style-3 Tilt" options={{ perspective:1000,speed:500,max : 15,scale:1 }}  >
                        <div className="cases-images">
                             <img className="img-fluid" src={require(`../../assets/images/cases/UncoverCaseStudy.jpg`)} alt=""/>
                        </div>
                        <div className="cases-desc">
                            <div> <span>Web App</span>
                                <h3 className = "text-white">Uncover Index</h3>
                            </div>
                        </div>
                    </Tilt>

                </div>

            </div>

        );
    }
}

export default CaseStudieBox;
