import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import  {ContactoText} from "../../text/Contacto/ContactoText";
import HeroSectionContactUs from "../../widgets/herosecation/HeroSectionContactUs";


class Contacto extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
        return (
          <div>


          <div id="main-page" className="page-content">
            {/*contact start*/}
            <HeroSectionContactUs />
            <section>
              {/*hero section start*/}

              {/*hero section end*/}

              {/*typeform

              <embed src={ContactoText[localStorage.getItem("language")].typeform} width="100%" height="500px" allowFullScreen></embed>


                  */}
              <div className="containerer">

                <div className="row align-items-end">
                  <div className="col text-center">
                    <div className="section-title">
                      <br/>                      <br/>
                      <br/>

                      <h6>{ContactoText[localStorage.getItem("language")].Contactanos}</h6>
                      <h2 className="title">{ContactoText[localStorage.getItem("language")].Title} <span>{ContactoText[localStorage.getItem("language")].Highlight}</span></h2>
                      <p>{ContactoText[localStorage.getItem("language")].p}</p>
                    </div>

                  </div>
                </div>
                <div className="row mt-5 no-gutters">
                  <div className="col-md-5">
                    <div className="dark-bg px-5 py-8 contact-info pos-r page-title  dark-bg text-center contactinfoanimation"> <i className="" />
                      <div className="contact-media mb-4"> <i className="flaticon-location" />
                        <h6 className="text-white">{ContactoText[localStorage.getItem("language")].Adress}</h6>
                        <span>{ContactoText[localStorage.getItem("language")].PAdress}</span>
                      </div>
                      <div className="contact-media mb-4"> <i className="flaticon-email" />
                        <h6 className="text-white"> {ContactoText[localStorage.getItem("language")].Mail}</h6>
                        <Link to="mailto:contacto@pointech.dev"> {ContactoText[localStorage.getItem("language")].Pmail}</Link>
                      </div>
                      <div className="contact-media"> <i className="flaticon-support-1" />
                        <h6 className="text-white">{ContactoText[localStorage.getItem("language")].Cel}</h6>
                        <Link to="tel:5578457977">{ContactoText[localStorage.getItem("language")].Pcel}</Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.4709003187645!2d-99.19172418509463!3d19.348752486932046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff7fb5bf8f7%3A0x8bce15bc71ea6d10!2sCalle%20Cracovia%2072-Int.%20A-114%2C%20San%20%C3%81ngel%2C%20%C3%81lvaro%20Obreg%C3%B3n%2C%2001000%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1631811463282!5m2!1ses-419!2smx" width={600} height="100%" style={{border: 0}} allowFullScreen loading="lazy" />
                  </div>
                </div>

              </div>
            </section>
            {/*contact end*/}
          </div>
          {/*body content end*/}
        </div>
          );
    }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Contacto);