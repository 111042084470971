import React from "react"
import { Link } from "react-router-dom"
export const HeroText5 = {
    EN: {
        subconst:"Solutions for",
        typer: ['Startups','SMEs','Big Companies','Institutions', 'Entrepreneurs','Agencies','Unicorns'],
        herotitle:"Make your hours count",
        button:"Get a Quote",
        typeform:"https://unuw5x9zxht.typeform.com/to/e8XLpUqY",
    },
    ES: {
        subconst: "Soluciones para",
        typer: ['Startups','PYMEs','Empresas Grandes','Instituciones','Emprendedores','Agencias','Unicornios'],
        herotitle:"Haz que tus horas cuenten",
        button:"Cotiza ahora",
        typeform:"https://unuw5x9zxht.typeform.com/to/MNbrgUWF",
    },
}