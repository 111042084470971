import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';
import ParticlesBg from '../PraticlesBg';

import {connect} from "react-redux";
import  {HeroText5} from "../../text/Servicios/Software/HeroText5";
import {IndexText} from "../../text/Index/IndexText";


class Herosection5 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section className="banner banner-5 fullscreen-banner o-hidden p-0 parallaxie" style={{ backgroundImage: 'url(' + require(`../../assets/images/bg/03.jpg`) + ')' }}  data-overlay={6}>
        <div className="align-center pt-0">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-8">
                <h4 className="text-white">{HeroText5[localStorage.getItem("language")].subconst} <Typed
                    style={{color:"#9500FFFF"}}
                    strings={HeroText5[localStorage.getItem("language")].typer}
                    typeSpeed={40}
                    backSpeed={50}
                    loop >
                  </Typed>
                  <span style={{ fontSize: '1.2em', verticalAlign: 'middle' }} className="cursor" data-cursordisplay="|" data-owner="some-id" /></h4>
                <h1 className="text-white font-w-7 mb-4">{HeroText5[localStorage.getItem("language")].herotitle}</h1>
                  <a className="btn btn-lg btn-outline-light ml-3 mr-lg-5" href={HeroText5[localStorage.getItem("language")].typeform} target="_blank" > {HeroText5[localStorage.getItem("language")].button} </a>
                
              </div>
            </div>
          </div>
        </div>
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
        <img src={require(`../../assets/images/bg/07.svg`)} alt="" className="round-shape" />
      </section>

    );
  }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Herosection5);

