import React from "react"
import { Link } from "react-router-dom"
export const HeroSection2Text = {
    EN: {
        subtitle:"Optimize your development",
        title:"Apps made simple",
    },
    ES: {
        subtitle:"Optimiza tu desarrollo",
        title:"Apps de manera fácil",
    },
}