import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {CrossplatformT} from "../../text/Servicios/Crossplatform/CrossplatformT";
import {connect} from "react-redux";

class Herosection3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section className="banner fullscreen-banner o-hidden gradient-bg p-0">
        <div className="banner-bg"  style={{ backgroundImage: 'url(' + require(`../../assets/images/bg/02.jpg`) + ')' }}    data-overlay={6} />
        <div className="align-center b-pl">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h6 className="text-uppercase letter-space-2 text-white">{CrossplatformT[localStorage.getItem("language")].title1}</h6>
                <h1 className="text-white mb-3"><span className="font-w-4">{CrossplatformT[localStorage.getItem("language")].title2}</span> {CrossplatformT[localStorage.getItem("language")].highlight}</h1>
                <p className="mb-4 text-light">{CrossplatformT[localStorage.getItem("language")].p}</p>
                <div className="col-lg-5 col-md-4 ">
                  <a className="btn btn-lg btn-outline-light ml-3 mr-lg-5" href={CrossplatformT[localStorage.getItem("language")].typeform} target="_blank" to={CrossplatformT[localStorage.getItem("language")].path}> {CrossplatformT[localStorage.getItem("language")].button} </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-left bg-transparent h-100 d-flex align-items-center justify-content-center">
          <div className="hero-social text-center">
            <ul className="list-unstyled">
              <li> <Link to="https://www.facebook.com/Pointech.consulting/"> <i className="fab fa-facebook-f" /></Link></li>
              <li> <Link to="https://www.instagram.com/pointech.dev/"> <i className="fab fa-instagram" /></Link></li>
              <li> <Link to="https://www.linkedin.com/company/69308202/"> <i className="fab fa-linkedin-in" /></Link></li>
            </ul>
          </div>
          <Link to="#main-page" className="scroll-down-arrow" />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Herosection3);