import React, {Component} from 'react';
import Pageheading from '../../widgets/Pageheading';
import {Link} from 'react-router-dom';



class Blog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loding: true,
            data : [],
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

    }

    render(){
        return (
            <div>
                {/*page title start*/}
                <Pageheading foldername={"Blog"} title={"Blogs"}/>
                {/*page title end*/}
                {/*body content start*/}
                <div id="main-page" className="page-content">
                    {/*blog start*/}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="post">
                                        <div className="post-image">
                                            <img className="img-fluid w-100"
                                                 src={require(`../../assets/images/blog/blog-heart.jpg`)} alt=""/>
                                        </div>
                                        <div className="post-desc">
                                            <div className="post-meta">
                                                <ul className="list-inline">

                                                    <li>June 02, 2021</li>
                                                </ul>
                                            </div>
                                            <div className="post-title">
                                                <h5>How is your website’s H.E.A.R.T? Metrics in UCD</h5>
                                            </div>
                                            <p>Web Development</p>
                                            <Link to="/blog/How-is-your-website-HEART"><i
                                                className="fas fa-long-arrow-alt-right mr-2"/> Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="post">
                                        <div className="post-image">
                                            <img className="img-fluid w-100"
                                                 src={require(`../../assets/images/blog/blog-scrum.jpg`)} alt=""/>
                                        </div>
                                        <div className="post-desc">
                                            <div className="post-meta">
                                                <ul className="list-inline">

                                                    <li>May 12, 2021</li>
                                                </ul>
                                            </div>
                                            <div className="post-title">
                                                <h5>Ready, set, SCRUM!</h5>
                                            </div>
                                            <p>Project Management</p>
                                            <Link to="/blog/Ready-set-SCRUM"><i
                                                className="fas fa-long-arrow-alt-right mr-2"/> Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-6">
                                    <div className="post">
                                        <div className="post-image">
                                            <img className="img-fluid w-100"
                                                 src={require(`../../assets/images/blog/blog-creatingwebsite.jpg`)} alt=""/>
                                        </div>
                                        <div className="post-desc">
                                            <div className="post-meta">
                                                <ul className="list-inline">

                                                    <li>April 08, 2021</li>
                                                </ul>
                                            </div>
                                            <div className="post-title">
                                                <h5>Creating the perfect website for your costumers</h5>
                                            </div>
                                            <p>Web Development</p>
                                            <Link to="/blog/How-to-create-the-perfect-website-for-your-costumers"><i
                                                className="fas fa-long-arrow-alt-right mr-2"/> Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*blog end*/}
                </div>
                {/*body content end*/}
            </div>
        );
    }
}

export default Blog;