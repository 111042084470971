import React from "react"
import { Link } from "react-router-dom"
export const IndexText = {
    EN: {
        HeroBnr:"Our Work",
        sectionTitle: "What we do",
        title:"We take your ideas",
        highlight:"And make them happen",
        text:"In Pointech we prepared to bring your ideas to reality through the use of different technologies. We have a diverse " +
            "and experienced team with have knowledge on different programming languages, frameworks, and platforms, allowing " +
            "us to take advantage of the benefits of each one to offer an optimized product for your audience. This way we make" +
            " sure to optimize the experience of your users and generate applications that meet your expectations.",
        li1:"React",
        li2:"Python & R",
        li3:"IOS & Android",
        li4:"Java, Swift &  C#",
        CSSubtitle:"Casos de Estudio",
        CSTitle:"Explore some of our ",
        CSHighlight:"Case Studies",
        TeamTitle:"We have a",
        TeamSubtitle:"Experience",
        TeamHighlight:"Team of Experts",
        TeamP:"Our team is trained to work with the best technology. Our multidisciplinary experience allows us to formulate solutions that take into account the different areas of your business in a holistic way, generating solutions tailored to your needs.",
        TeamP2: "We analyze the technological infrastructure, processes and overall structure" +
            " of your company to strengthen the short-term performance of the organization and maintain a long-term sustainable improvement.",
        FeatureST:"Why Choose Us",
        FeatureT:"Our",
        FeatureHighlight:"Benefits",
        BlogSecT:"Read Our",
        BlogSecSub:"Stay conected",
        BlogSecHighlight:"Latest News",

    },
    ES: {
        HeroBnr:"We make",
        sectionTitle: "Nuestro Trabajo",
        title: "Llevamos tus ideas",
        highlight: "a la realidad",
        text: "En Pointech estamos preparados para llevar tus ideas a la realidad a través del uso de distintas tecnologías. Contamos con un equipo experimentado y diverso " +
            "con conocimiento de distintos lenguajes de programación, frameworks y plataformas, permitiéndonos aprovechar los beneficios de cada uno para ofrecer un producto optimizado para tu publico. De esta forma nos aseguramos de optimizar la experiencia de tus usuarios y generar apps que cumplan con tus expectativas.",
        li1:"React",
        li2:"Python y R",
        li3:"IOS y Android",
        li4:"Java, Swift y  C#",
        CSSubtitle:"Nuestro Trabajo",
        CSTitle:"Explora algunos de nuestros",
        CSHighlight:"Casos de Estudio",
        TeamTitle:"Tenemos un",
        TeamSubtitle:"Experiencia",
        TeamHighlight:"Equipo de Expertos",
        TeamP:"Nuestro equipo está capacitado para trabajar con la mejor tecnología. Nuestra experiencia multidiciplinaria nos permite formular soluciones que tomen en cuenta los distintos rubros de tu negocio de una forma holística, generando soluciones a la medida de tus necesidades.",
        TeamP2: "Analizamos la infraestructura tecnológica, los procesos y las estructuras " +
            "de su empresa para fortalecer el desempeño a corto plazo de la organización y mantener una mejora sostenible a largo plazo. ",
        FeatureST:"Por Qué Nosotros",
        FeatureT:"Nuestros",
        FeatureHighlight:"Beneficios",
        BlogSecT:"Lee nuestras",
        BlogSecSub:"Mantente Conectado",
        BlogSecHighlight:"Ultimas Noticias",

    },
}