import React, { Component } from 'react';
import { connect } from "react-redux";
import  {FeatureBox2Text} from "../../text/Index/FeatureBox2Text";

class FeatureBox2 extends Component {
    constructor(props)
    {
      super(props);
      this.state = {
        isHovered: 1
      };
    }
    handleMenuHover(itemno) {
      this.setState({
        isHovered: itemno
      });
    }
    render() {
        const {isHovered} = this.state;
        return (
          <div className="row">
          <div className="col-lg-6 col-md-6" >
            <div className={(isHovered == 1) ? 'featured-item style-1 active' : 'featured-item style-1'}  onMouseEnter={() => this.handleMenuHover(1)}    >
              <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-ui" />
              </div>
              <div className="featured-title">
                <h5>{FeatureBox2Text[localStorage.getItem("language")].BoxTI1}</h5>
              </div>
              <div className="featured-desc">
                <p>{FeatureBox2Text[localStorage.getItem("language")].BoxPI1}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mt-5 mt-md-0">

          <div className={(isHovered == 2) ? 'featured-item style-1 active' : 'featured-item style-1'}  onMouseEnter={() =>  this.handleMenuHover(2)}    >
              <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-idea" />
              </div>
              <div className="featured-title">
                <h5>{FeatureBox2Text[localStorage.getItem("language")].BoxTI2}</h5>
              </div>
              <div className="featured-desc">
                <p>  {FeatureBox2Text[localStorage.getItem("language")].BoxPI2}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mt-5">
          <div className={(isHovered == 3) ? 'featured-item style-1 active' : 'featured-item style-1'}  onMouseEnter={() => this.handleMenuHover(3)}    >
              <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-analytics" />
              </div>
              <div className="featured-title">
                <h5>{FeatureBox2Text[localStorage.getItem("language")].BoxTI3}</h5>
              </div>
              <div className="featured-desc">
                <p>{FeatureBox2Text[localStorage.getItem("language")].BoxPI3}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mt-5">
          <div className={(isHovered == 4) ? 'featured-item style-1 active' : 'featured-item style-1'}  onMouseEnter={() => this.handleMenuHover(4)}    >
              <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-optimization" />
              </div>
              <div className="featured-title">
                <h5>{FeatureBox2Text[localStorage.getItem("language")].BoxTI4}</h5>
              </div>
              <div className="featured-desc">
                <p>{FeatureBox2Text[localStorage.getItem("language")].BoxPI4}</p>
              </div>
            </div>
          </div>
        </div>
     
        );
    }
}

const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(FeatureBox2);