import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import {connect} from "react-redux";
window.fn = OwlCarousel;
import {BlogFooterTexto} from "../../text/Index/BlogFooterTexto";

class BlogStyle3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          options: {
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1000: {
                items: 2,
              },
          },
        };
    }
    render() {
        return (
            <div className="row">
                  <div className="col-lg-11 col-12 ml-auto mr-lg-n5">
                    <OwlCarousel
                        items={this.props.logoitems}
                        margin={30}
                        autoplay={true}
                        loop={true}
                        responsive={this.state.options}
                    >

                      {/*Blog 1 Start*/}
                      {/*BLOG HEART*/}
                      <div className="item">
                        <div className="row no-gutters post style-2">
                          <div className="col-sm-6">
                            <div className="post-image h-100" style={{ backgroundImage: 'url(' + require(`../../assets/images/sevices/uber.jpg`) + ')' }} />
                          </div>
                          <div className="col-sm-6">
                            <div className="post-desc">
                              <div className="post-meta">

                                <ul className="list-inline">
                                  <li>Mobile App</li>
                                </ul>

                              </div>

                              <div className="post-title">
                                <h5>Uber: App de transporte terestre</h5>
                              </div>
                                <br/>

                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*Blog 1 End*/}


                        {/*Blog 2 Start*/}
                        {/*BLOG SCRUM*/}
                      <div className="item">
                        <div className="row no-gutters post style-2">
                          <div className="col-sm-6">
                            <div className="post-image h-100" style={{ backgroundImage: 'url(' + require(`../../assets/images/sevices/pinterest.jpg`) + ')' }} />
                          </div>
                          <div className="col-sm-6">
                            <div className="post-desc">
                              <div className="post-meta">
                                <ul className="list-inline">

                                  <li>WebApp</li>
                                </ul>
                              </div>
                              <div className="post-title">
                                <h5>Pinterest: Web app de diseño</h5>
                                <div><br/><br/></div>
                              </div>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                        {/*Blog 2 Ends*/}


                      {/*Blog 3 Starts*/}
                      {/*BLOG CREATING WEBSITE*/}
                      <div className="item">
                        <div className="row no-gutters post style-2">
                          <div className="col-sm-6">
                            <div className="post-image h-100"  style={{ backgroundImage: 'url(' + require(`../../assets/images/sevices/PS.jpg`) + ')' }}/>
                          </div>
                          <div className="col-sm-6">
                            <div className="post-desc">
                              <div className="post-meta">
                                <ul className="list-inline">

                                  <li>Desktop</li>
                                </ul>
                              </div>
                              <div className="post-title">
                                <h5>PhotoShop: Desktop app de diseño</h5>
                                <div><br/></div>
                              </div>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*Blog 3 Ends*/}


                    </OwlCarousel>
                  </div>
                </div>
             
        );
    }
}


const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(BlogStyle3);
