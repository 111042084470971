/**
 *  Contact Json
 */
export default {
    ES: {
            "menu_title": "Contacto",
            "type": "",
            "path": "/Contacto",
            "icon": "contact",
        },
    EN: {
        "menu_title": "Contact",
        "type": "",
        "path": "/Contacto",
        "icon": "contact",
    },




}