import React,{Component} from 'react';
import OwlCarousel from 'react-owl-carousel';  
window.fn = OwlCarousel;

class Testimonial4 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
             <OwlCarousel
                    className={`owl-carousel`}
                    items={this.props.logoitems}
                    autoplay={true}
                    loop={true}
                >

                 <div className="item">
                     <div className="testimonial style-2">
                         <div className="testimonial-content">
                             <div className="testimonial-quote"><i className="flaticon-left-quote" />
                             </div>
                             <p>El equipo de Point nos ayudó a llevar a cabo un proyecto a largo plazo con el que pudimos optimizar la capacidad de nuestro equipo. Estábamos acostumbrados a usar Excel y nunca le dimos  importancia de la ingeniería de los datos.</p>
                         </div>
                         <div className="d-flex align-items-center">
                             <div className="testimonial-img">
                                 <img className="img-fluid"  src={require(`../../assets/images/testimonial/c.jpg`)} alt="" />
                             </div>
                             <div className="testimonial-caption">
                                 <h5>Mariana Ruiz Fernandez</h5>
                                 <label>Directora de Operaciones de Myp</label>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div className="item">
                     <div className="testimonial style-2">
                         <div className="testimonial-content">
                             <div className="testimonial-quote"><i className="flaticon-left-quote" />
                             </div>
                             <p>Jamás le había dado tanta importancia al diseño, me sirvió mucho la experiencia del equipo para el desarrollo del producto y me gustó mucho su acercamiento enfocado en el usuario.</p>
                         </div>
                         <div className="d-flex align-items-center">
                             <div className="testimonial-img">
                                 <img className="img-fluid"  src={require(`../../assets/images/testimonial/b.jpg`)} alt="" />
                             </div>
                             <div className="testimonial-caption">
                                 <h5>José Luis Altamirano</h5>
                                 <label>Gerente de Sistemas de Asturias</label>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div className="item">
                     <div className="testimonial style-2">
                         <div className="testimonial-content">
                             <div className="testimonial-quote"><i className="flaticon-left-quote" />
                             </div>
                             <p>Con Point, no sólo incrementaron mis ventas. Hicieron mucho más fácil el trabajo del equipo con los cuestionarios y las diferentes herramientas que implementaron. Recomiendo ampliamente. </p>
                         </div>
                         <div className="d-flex align-items-center">
                             <div className="testimonial-img">
                                 <img className="img-fluid"  src={require(`../../assets/images/testimonial/a.jpg`)} alt="" />
                             </div>
                             <div className="testimonial-caption">
                                 <h5>Daniel Carranza</h5>
                                 <label> Brand Manager de Agencio</label>
                             </div>
                         </div>
                     </div>
                 </div>
                 
                
            </OwlCarousel>

        );
    }
}

export default Testimonial4;
