import React from "react"
import { Link } from "react-router-dom"
export const FeatureBoxText = {
    EN: {
        Title1:"UX & UI",
        p1:"For us, there are two main concepts you should never oversee: user experience and user interface (UX/UI). Both concepts complement each other and create an efficient customer experience.",
        Title2:"Quality",
        p2:"Due to our high quality standards, we use the best development practices to minimize our customers' technical debt.",
        Title3:"Strategy",
        p3:"We seek to collaborate with our clients. By combining our knowledge in digital strategy and the knowledge of the client of their own company, we are able to generate tailor-made, unique and efficient products.",
    },
    ES: {
        Title1:"UX y UI",
        p1:"Para nosotros es importante darle prioridad a la experiencia e interfaz del usuario (UX/UI). Ambos conceptos se complementan creando una experiencia eficiente para el cliente.",
        Title2:"Calidad",
        p2:"Debido a nuestros altos estándares de calidad, utilizamos las mejores prácticas de desarrollo para minimizar la deuda técnica de nuestros clientes. ",
        Title3:"Estrategia",
        p3:"Buscamos colaborar con nuestros clientes. Al conjuntar nuestro conocimiento en estrategia digital y el conocimiento del cliente de su propia empresa, logramos generar productos hechos a la medida, únicos y eficientes.",
    },
}