import React from "react"
import { Link } from "react-router-dom"
export const HeroSect4T = {
    EN: {
        t1:"Fill a Quick Questionnaire To Get a Quote",
        desc1:"This will help us accelerate the process of getting back to you with an estimated budget",
        t2:"Mobile App Development",
        path:"/EnglishAppForm",
        buttonmovil:"Fill out development form",
        buttonweb:"Fill out development form",
        typeform:"https://unuw5x9zxht.typeform.com/to/cPnVqj46",

    },
    ES: {
        t1: "Complete un cuestionario rápido para obtener una cotización",
        desc1:"Esto nos ayudará a acelerar el proceso de contactarte con un presupuesto aproximado.",
        t2:"Desarrollo de Apps Móviles",
        path:"/SpanishAppForm",
        buttonmovil:"Rellenar cuestionario de desarrollo",
        buttonweb:"Rellenar cuestionario de desarrollo",
        typeform:"https://unuw5x9zxht.typeform.com/to/LYgj7sVX",
    },
}

