import React from "react"
import { Link } from "react-router-dom"
export const ContactoText = {
    EN: {
        Banner:"Take the next Step",
        Section:"Contact Us",
        Contactanos:"Contact Us",
        Highlight:"Conversation",
        Title:"Let's Start a",
        par:"Get in touch and let us know how we can help. Fill out the form and we'll reach out as soon as possible.",
        Adress:"Adress",
        PAdress:"Calle Cracovia 72, Int. A-114, San Ángel, CDMX, Mexico",
        Mail:"Email Us",
        Pmail:"contacto@pointech.dev",
        Cel:"Call Us",
        Pcel:"55-78-45-79-77",
        typeform:"https://unuw5x9zxht.typeform.com/to/e8XLpUqY",
    },
    ES: {
        Banner:"Da el Siguiente Paso",
        Section:"Contáctanos",
        Contactanos:"Contáctanos",
        Highlight:"Conversación",
        Title:"Empecemos una",
        par:"Ponte en contacto y cuéntanos cómo podemos ayudarte. Llena el formulario y nos comunicaremos a la brevedad.",
        Adress:"Dirección",
        PAdress:"Calle Cracovia 72, Int. A-114, San Ángel, CDMX, México",
        Mail:"Envíanos un Correo",
        Pmail:"contacto@pointech.dev",
        Cel:"Llámanos",
        Pcel:"55-78-45-79-77",
        typeform:"https://unuw5x9zxht.typeform.com/to/In661vPA",
    },
}