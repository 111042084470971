import React, { Component } from 'react';

import Herosection5 from '../../widgets/herosecation/Herosection5';
import SkillBox1 from '../../widgets/skill/SkillBox1';

import FeatureBox5 from '../../widgets/featurebox/FeatureBox5';

import PriceTable1 from '../../widgets/price/PriceTable1';
import Clientbox1 from '../../widgets/client/Clientbox1';
import Testimonial4 from '../../widgets/testimonial/Testimonial4';

import FAQDeveleopment from '../components/faq/FAQDevelopmentPlans';


import { connect } from "react-redux";
import  {SoftwareT} from "../../text/Servicios/Software/SoftwareT";




class DevelopmentPlans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <div>
        {/*hero section start*/}
        <Herosection5 />
        {/*hero section end*/}
        {/*body content start*/}
        <div id="main-page" className="page-content">
          {/*feature start*/}
          <section className="p-0 custom-mt-10">
            <div className="container" style={{paddingBottom:"10%"}}>
              <SkillBox1 />
            </div>
          </section>
          {/*feature end*/}

          {/*Lo que ganas start*/}
          <section className="custom-mt-10 pt-0 text-center">
            <div className="container">
              <FeatureBox5 />
            </div>
          </section>
          {/*Lo que ganas end*/}
          {/*FAQ start*/}
          <section className="pt-0">
            <div className="container">
              <h1 className="title">FAQ</h1>
              <br/>
              <FAQDeveleopment />
            </div>
          </section>
          {/*FAQ end*/}


         
        </div>
        {/*body content end*/}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(DevelopmentPlans);


