import React, { Component } from 'react';
import CountUp from 'react-countup';

import { connect } from "react-redux";
import {HeroSect4T} from "../text/Servicios/AppsMovil/HeroSect4T";


class Cuestionario extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <section className="white-bg parallaxie" style={{ backgroundImage: 'url(' + require(`../assets/images/pattern/03.png`) + ')' }}   >
                <div className="container-fluid pr-lg-0">
                    <div className="alignCenter ">
                        <div className='featured-item style-3 active'>
                            <div className="featured-title">
                                <h5>{HeroSect4T[localStorage.getItem("language")].t1}</h5>
                            </div>
                            <div className="featured-desc">
                                <p>{HeroSect4T[localStorage.getItem("language")].desc1}</p>
                                <br/>
                                <div className="alignCenter">
                                    {/*typeform link*/}
                                    <div className="col-lg-5 col-md-5 ">
                                        <a className="btn btn-lg btn-outline-light ml-3 mr-lg-5" href={HeroSect4T[localStorage.getItem("language")].typeform} target="_blank" to={HeroSect4T[localStorage.getItem("language")].path}> {HeroSect4T[localStorage.getItem("language")].buttonweb} </a>
                                    </div>
                                    {/*typeform link*/}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(Cuestionario);