import React, { Component } from 'react';
import Pageheading from '../../widgets/Pageheading';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                {/*page title start*/}
                <Pageheading foldername={"Home"} title={"Privacy Policy"}/>
                {/*page title end*/}
                {/*body content start*/}
                <div id="main-page" className="page-content">
                    {/*privacy start*/}
                    <section className="container">
                        <h1 className="section-title"> Política de Privacidad</h1>
                        <p>*De acuerdo en lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión
                            de los Particulares, (LFPDPPP), (en adelante la "Ley"), declara Pointechnology Integral
                            Consulting, S.C. en lo sucesivo "Point" una empresa legalmente constituida de conformidad
                            con las leyes mexicanas, con domicilio en Cerro del Otate 309, C.P. 04320, CDMX; como
                            responsables del tratamiento de sus datos personales, por lo que a continuación hacen de su
                            conocimiento la política de privacidad aplicada para estos fines.</p>
                        <h4 className="section-title">Datos personales que se pueden recabar</h4>
                        <p>"Point" recabará todos los datos personales de los clientes y/o proveedores que sean
                            necesarios, como ejemplo de estos datos (más no limitándose únicamente a los aquí
                            mencionados) se encuentran: Nombre, apellido, correo electrónico, fecha de nacimiento, lugar
                            de nacimiento, país de nacimiento, nacionalidad, ocupación, nivel de ingresos, domicilio
                            particular, números telefónicos, Clave Única de Registro de Población (CURP), RFC, entre
                            otros, comprobantes o documentos oficiales que acrediten su identidad y la información
                            proporcionada. El cliente declara que al llenar la solicitud y proporcionarnos sus datos,
                            tenemos su acuerdo tácito para su verificación.</p>
                        <h4 className="section-title">Uso de la información</h4>
                        <p>"Point" utiliza y procesa los datos recabados de los clientes y proveedores de manera
                            confidencial, con la finalidad de identificar debidamente al cliente o proveedor según sea
                            el caso, para contactarlo. Para prevenir el acceso no autorizado a sus datos personales,
                            hemos establecido diversos procedimientos, así como el uso de medios técnicos,
                            administrativos y digitales que permiten el tratamiento debido de dicha información. Así
                            mismo, se informa que mantenemos la información de nuestros clientes y proveedores por un
                            tiempo razonable, y de acuerdo con las disposiciones legales aplicables, por lo que una vez
                            que los datos personales de nuestros clientes o proveedores hayan dejado de ser necesarios
                            para el cumplimiento de lo previsto en el presente aviso de privacidad, y en las
                            disposiciones legales aplicables, serán archivados.</p>
                        <h4 className="section-title">Derechos de acceso, rectificación, cancelación y oposición (ARCO)</h4>
                        <p>Los clientes de "Point" en todo momento pueden ejercer sus derechos de acceso, rectificación,
                            cancelación y oposición (ARCO), referentes al tratamiento de sus datos personales, siempre y
                            cuando resulte procedente.</p>
                        <h4 className="section-title">Modificaciones al aviso de privacidad</h4>
                        <p>Este aviso de privacidad podrá ser modificado total o parcialmente por "Point". Dichas
                            modificaciones serán oportunamente notificadas a los clientes a través de su actualización
                            en la página web de "Point" www.pointech.dev.</p>
                    </section>
                    {/*privacy end*/}
                </div>
                {/*body content end*/}
            </div>
        );
    }
}

export default PrivacyPolicy;
