import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import  {PricingTableT} from "../../text/Servicios/Crossplatform/PricingTableT";

class PriceTable1 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="price-table ">
                <div className="price-header">
                  <h5 className="price-title">{PricingTableT[localStorage.getItem("language")].plan1}</h5>
                  <p >{PricingTableT[localStorage.getItem("language")].p1}</p>
                </div>
                <div className="price-value">
                  <h2>
                    <span className="price-dollar">$</span>{PricingTableT[localStorage.getItem("language")].price1}
                  </h2>
                </div>
                <div className="price-list">
                  <ul className="list-unstyled">
                    <li><i className="fas fa-check" />{PricingTableT[localStorage.getItem("language")].p1l1}</li>
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p1l2}</li>
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p1l3}</li>
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p1l4}</li>
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p1l5}</li>
                  </ul>
                </div>
                <Link className="btn btn-white btn-block mt-4" to={"./Contacto"}> <span>Get Started <i className="fas fa-long-arrow-alt-right ml-2" /></span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 md-mt-5">
              <div className="price-table ">
                <div className="price-header">
                  <h5 className="price-title">{PricingTableT[localStorage.getItem("language")].plan2}</h5>
                  <p>{PricingTableT[localStorage.getItem("language")].p2}</p>
                </div>
                <div className="price-value">
                  <h2>
                    <span className="price-dollar">$</span>{PricingTableT[localStorage.getItem("language")].price2}
                  </h2>
                </div>
                <div className="price-list">
                  <ul className="list-unstyled">
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p2l1}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p2l2}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p2l3}</li>
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p2l4}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p2l5}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p2l6}</li>

                  </ul>
                </div>          
                <Link className="btn btn-white btn-block mt-4" to={"./Contacto"}> <span>Get Started <i className="fas fa-long-arrow-alt-right ml-2" /></span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 md-mt-5">
              <div className="price-table  dark-bg">
                <div className="price-header text-white ">
                  <h5 className="price-title">{PricingTableT[localStorage.getItem("language")].plan3}</h5>
                  <p>{PricingTableT[localStorage.getItem("language")].p3}</p>
                </div>
                <div className="price-value text-white">
                  <h2 className="text-white">

                    {PricingTableT[localStorage.getItem("language")].price3}

                  </h2>
                </div>
                <div className="price-list text-white">
                  <ul className="list-unstyled">
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p3l1}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p3l2}</li>
                    <li><i className="fas fa-check" /> {PricingTableT[localStorage.getItem("language")].p3l3}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p3l4}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p3l5}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p3l6}</li>
                    <li><i className="fas fa-check" />  {PricingTableT[localStorage.getItem("language")].p3l7}</li>


                  </ul>
                </div>          
                <Link className="btn btn-white btn-block mt-4" to={"./Contacto"}> <span>Get Started <i className="fas fa-long-arrow-alt-right ml-2" /></span>
                </Link>
              </div>
            </div>
          </div>
       
            );
    }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(PriceTable1);

