import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import {IndexText} from "../../text/Index/IndexText";
import {connect} from "react-redux";
import  {FooterText} from "../../text/Footer/FooterText";

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
            <div className="primary-footer">
              <div className="container">
                <div className="row mb-10 align-items-center">
                  <div className="col-lg-8 col-md-7">
                    <h2 className="title text-white">{FooterText[localStorage.getItem("language")].Title}</h2>
                  </div>
                  <div className="col-lg-4 col-md-5 text-md-right mt-4 mt-md-0">
                    <Link to ="/contacto" className="btn btn-theme" ><span>{FooterText[localStorage.getItem("language")].Button} <i className="fas fa-plus-square" /></span></Link>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-6">
                    <div className="footer-logo mb-3">
                      <Link to="/"><img className="img-fluid" src={require(`../../assets/images/logo.png`)} alt="" /></Link>
                    </div>
                    <p className="mb-0">{FooterText[localStorage.getItem("language")].LeftFoot} </p>
                  </div>
                  <div className="col-lg-6 ml-auto col-md-6 mt-5 mt-md-0">
                    <h4 className="mb-4 text-white">{FooterText[localStorage.getItem("language")].RightFoot}</h4>
                    <div className="footer-list">
                      <ul className="list-inline">
                        <li><Link to="/">{FooterText[localStorage.getItem("language")].Home}</Link>
                        </li>
                        <li><Link to="/about-us">{FooterText[localStorage.getItem("language")].AboutUs}</Link>
                        </li>
                        <li> <Link to="/blog-left-sidebar">{FooterText[localStorage.getItem("language")].Blog}</Link>
                        </li>
                        <li> <Link to="/contacto">{FooterText[localStorage.getItem("language")].Contacto}</Link>
                        </li>
                        <li><Link to="/privacy-policy">{FooterText[localStorage.getItem("language")].PrivacyPolicy}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="secondary-footer">
              <div className="container">
                <div className="copyright">
                  <div className="row align-items-center">
                    <div className="col-lg-7 order-lg-1 text-lg-right"> <span>Copyright 2021 by <u><Link to="/">Point.</Link></u> | {FooterText[localStorage.getItem("language")].Rights}</span>
                    </div>
                    <div className="col-lg-5 mt-3 mt-lg-0">
                      <div className="footer-social">
                        <ul className="list-inline">
                          <li><a href="https://www.facebook.com/Pointech.consulting" target="_blank" ><i className="fab fa-facebook-f" /></a>
                          </li>
                          <li><a href="https://www.instagram.com/pointech.dev/" target="_blank" ><i className="fab fa-instagram" /></a>
                          </li>
                          <li><a href="https://www.linkedin.com/company/69308202/" target="_blank" ><i className="fab fa-linkedin-in" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dot-bg">
              <img className="img-fluid" src={require(`../../assets/images/pattern/05.png`)} alt="" />
            </div>
          </footer>
        );
    }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Footer);