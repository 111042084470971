import React, { Component } from 'react';
import {connect} from "react-redux";
import {FeatureBoxText} from "../../text/Index/FeatureBoxText";

class FeatureBox1 extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            <div className="row">
            <div className="col-md-4">
              <div className="featured-item style-3 text-center">
                <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-ui" />
                    <span className="border-anim" />
                </div>
                <div className="featured-title">
                  <h5>{FeatureBoxText[localStorage.getItem("language")].Title1}</h5>
                </div>
                <div className="featured-desc">
                  <p>{FeatureBoxText[localStorage.getItem("language")].p1}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="featured-item style-3 text-center">
                <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-domain" />
                    <span className="border-anim" />
                </div>
                <div className="featured-title">
                  <h5>{FeatureBoxText[localStorage.getItem("language")].Title2}</h5>
                </div>
                <div className="featured-desc">
                  <p>{FeatureBoxText[localStorage.getItem("language")].p2}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5 mt-md-0">
              <div className="featured-item style-3 text-center">
                <div className="featured-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-challenges" />
                    <span className="border-anim" />
                </div>
                <div className="featured-title">
                  <h5>{FeatureBoxText[localStorage.getItem("language")].Title3}</h5>
                </div>
                <div className="featured-desc">
                  <p>{FeatureBoxText[localStorage.getItem("language")].p3}</p>
                </div>
              </div>
            </div>
          </div>
        );
    }
}


const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(FeatureBox1);