import React from "react"
import { Link } from "react-router-dom"
export const AboutUsText = {
    EN: {
        BoxCont:"Multidiciplinary Team With Experience in More Than 10 Industries",
        Subtitle:"Who are we",
        Title:"An Agency",
        Highlight:"Specialized in development",
        p:"We have experience with clients from different sectors. We have generated solutions for various industries and" +
            " specific needs. With Point. you can bring your idea to reality, whether to optimize costs, start a new project or streamline your operations.",

        p2: "By implementing cost-effective and customer-centered digital transformation strategies, " +
            "we help companies acquire, retain and assist customers in more innovative ways using new technologies.",
        prom1:"Average hours of development per client",
        prom2:"Lines of code written",
    },
    ES: {
        BoxCont:"Equipo Multidisciplinario con Experiencia en Más de 10 Industrias",
        Subtitle:"Quiénes somos",
        Title:"Una agencia",
        Highlight:"Especializada en desarrollo",
        p:"Tenemos experiencia con clientes de distintos sectores. Hemos generado soluciones para diversas industrias y " +
            "necesidades específicas. Con Point. tu también puedes llevar tu idea a la realidad, ya sea para optimizar costos, empezar un proyecto nuevo o agilizar tus operaciones.",
        p2:"Al implementar estrategias de transformación digital rentables y centradas en el cliente, " +
            "ayudamos a las empresas a adquirir, retener y ayudar a los clientes de manera más innovadora utilizando nuevas tecnologías.",

        prom1:"Horas promedio de desarrollo por cliente",
        prom2:"Líneas de código escritas",
    },
}