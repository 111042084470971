import React, { Component } from 'react';
import Herosection2 from '../../widgets/herosecation/Herosection2';


import { connect } from "react-redux";


import {HeroSect4T} from "../../text/Servicios/AppsMovil/HeroSect4T";

import FeatureBox5 from "../../widgets/featurebox/FeatureBox5";
import Cuestionario from "../../widgets/cuestionario";
import WebAppsHero from "../../widgets/herotexts/webappshero";
import {AppsWebT} from "../../text/Servicios/AppsWeb/AppsWebT";
import BlogStyle3 from "../../widgets/Blog/BlogStyle3";




class AppsWeb extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <div className="dark-bg">
                    <Herosection2 />

                </div>
                <WebAppsHero/>


                {/*hero section end*/}
                {/*body content start*/}
                <div id="main-page" className="page-content">
                    {/*client start*/}
                    <section className="light-main-bg parallaxie" style={{ backgroundImage: 'url(' + require(`../../assets/images/pattern/03.png`) + ')' }}   >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12">
                                    <div className="section-title">
                                        <h6>{AppsWebT[localStorage.getItem("language")].BlogSecSub}</h6>
                                        <h2 className="title"> {AppsWebT[localStorage.getItem("language")].BlogSecT} <span> {AppsWebT[localStorage.getItem("language")].BlogSecHighlight}</span></h2>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid pr-lg-0">
                            <BlogStyle3 logoitems={2} />
                        </div>
                    </section>

                    {/*client end*/}
                    {/*service start*/}
                    <section className="pos-r text-center">
                        <div className="container">
                            <FeatureBox5 />
                        </div>
                    </section>
                    {/*service end*/}


                    {/*blog start*/}
                        <Cuestionario/>
                    {/*blog end*/}
                </div>



                {/*body content end*/}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(AppsWeb);