import React from "react"
import { Link } from "react-router-dom"
export const FeatureBox2Text = {
    EN: {
        BoxTI1:"Applications",
        BoxTI2:"Web Development",
        BoxTI3:"Other Digital Ecosystems",
        BoxTI4:"Solution Design",
        BoxPI1:"Apps for all types of devices.",
        BoxPI2:"From landing pages to specialized server applications.",
        BoxPI3:"Bots, IOT, Machine Learning and other ecosystems.",
        BoxPI4:"Development Solutions tied up to your personal needs.",
    },
    ES: {
        BoxTI1:"Aplicaciones",
        BoxTI2: "Desarrollo Web",
        BoxTI3:"Otros Ecosistemas Digitales",
        BoxTI4:"Diseño de Soluciones",
        BoxPI1:"Apps para todo tipo de dispositivos.",
        BoxPI2:"Desde landing pages hasta aplicaciones especializadas en servidor.",
        BoxPI3:"Bots, IOT, Aprendizaje de Máquina y otros ecosistemas.",
        BoxPI4:"Soluciones de desarrollo ajustadas a tus necesidades.",
    },
}