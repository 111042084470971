import React from "react"
import { Link } from "react-router-dom"
export const MobileAppHeroT = {
    EN: {
        BoxCont: "Multidisciplinary Team with Experience in More than 10 Industries",
        Subtitle: "Mobile Apps",
        Title: "native mobile development for",
        Highlight: "iOS, Android and cross-platform applications.",
        p: "We develop apps for mobile phones and tablets, from converting a web page to an application, to developing a completely original concept." +
            "We offer consulting for the development of mobile applications and commercial analysis to understand your needs" +
            "and obtain requirements, define and prioritize functions, as well as plan the solution architecture and perform usability tests.",
        p2: "We elaborate the UI / UX design for mobile apps by creating UX wireframes and UI design of the application.",

        prom1: "Average hours of development per client",
        prom2: "Lines of code written",
    },
    ES: {
        BoxCont:"Equipo Multidisciplinario con Experiencia en Más de 10 Industrias",
        Subtitle:"Apps Móviles",
        Title:"desarrollo móvil nativo para",
        Highlight:"iOS, Android y  desarrollo multiplataforma.",
        p:"Desarrollamos apps para teléfono celular y tablet, desde convertir una página web a una aplicación, hasta desarrollar un concepto completamente original. " +
            "Ofrecemos consultoría para el desarrollo de aplicaciones móviles y análisis comercial para entender sus necesidades" +
            " y obtener requisitos, definir y priorizar funciones, así como planificar la arquitectura de la solución y realizar pruebas de usabilidad.",
        p2:"Elaboramos el diseño de UI / UX para apps móviles mediante la creación de wireframes UX y diseño del UI de la aplicación.",

        prom1:"Horas promedio de desarrollo por cliente",
        prom2:"Líneas de código escritas",
    },
}