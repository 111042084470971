import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import  {ContactoText} from "../../../text/Contacto/ContactoText";
import HeroSectionContactUs from "../../../widgets/herosecation/HeroSectionContactUs";
import {HeroSect4T} from "../../../text/Servicios/AppsMovil/HeroSect4T";


class Carlos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animationClass: 'test'
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
        return (
          <div>


          <div id="main-page" className="page-content">
            {/*contact start*/}
            <section className="banner pos-r fullscreen-banner o-hidden heroanimationcontactus" >

              <div className="align-center pt-0 b-pl">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-md-5 offset-lg-1 offset-md-1">
                      <h1 className="text-white">CARLOS ANDRES LARA </h1>
                    </div>

                    <div className="col-lg-5 col-md-5 ">
                      <div className="container">
                        <div className="row align-items-end">
                          <div className="col text-center">
                              <h6 style={{color: "white"}}>carlos.lara@pointech.dev</h6>
                              <h2 style={{color: "white"}} className="title">Co-Founder / CEO</h2>
                            <p style={{color: "white"}}>+52 <span style={{color: "white"}}>55 7845 7977</span></p>
                          </div>
                        </div>
                    </div>

                  </div>
                </div>
              </div>
              </div>



            </section>


          </div>
          {/*body content end*/}
        </div>
          );
    }
}

const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Carlos);