import React from "react"
import { Link } from "react-router-dom"
export const AppsWebT = {
    EN: {
        BlogSecSub:"App vs Webapp",
        BlogSecHighlight:"",
        BlogSecT:"Some examples",
    },
    ES: {
        BlogSecSub:"App vs Webapp",
        BlogSecHighlight:"",
        BlogSecT:"Algunos ejemplos",
    },
}
