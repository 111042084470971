import React from "react"
import { Link } from "react-router-dom"
export const PricingTableT = {
    EN: {
        plan1:"Landing Page",
        p1:"One page with five sections. Clean and effective.",
        price1:"750 USD",
        p1l1:"5 sections",
        p1l2:"Template Selection",
        p1l3:"Frontend Maintenance",
        p1l4:"Basic 24/7 Support",
        p1l5:"SSL, SEO, SEM",

        plan2:"Full Site",
        p2:"Full site with a different page for each section.",
        price2:"1500 USD",
        p2l1:"Required Sections",
        p2l2:"Premium Template Selection",
        p2l3:"Third Party Integrations",
        p2l4:"Premium 24/7 Support",
        p2l5:"Frontend Maintenance",
        p2l6:"SSL, SEO, SEM",


        plan3:"Webapp",
        p3:"Website with more robust functionalities.",
        price3:"Contact Us",
        p3l1:"Fully customizeable",
        p3l2:"Robust Integrations",
        p3l3:"Ground Up design",
        p3l4:"Personal 24/7 Support",
        p3l5:"Content Curation",
        p3l6:"Specific Functional Design",
        p3l7:"SSL, SEO, SEM",
        pricingT:"Choose the Best",
        pricingH:"Product for you",
        pricingP:"Determine the product you need based on the level of specialization",
    },
    ES: {
        plan1:"Landing Page",
        p1:"Una página con cinco secciones. Limpio y efectivo.",
        price1:"15,000 MXN",
        p1l1:"5 secciones",
        p1l2:"Selección de template",
        p1l3:"Mantenimiento Frontend",
        p1l4:"Soporte Básico 24/7",
        p1l5:"SSL, SEO, SEM",

        plan2:"Full Site",
        p2:"Full site con una página para cada sección.",
        price2:"30,000 MXN",
        p2l1:"Secciones Solicitadas",
        p2l2:"Selección de Template Premium",
        p2l3:"Integraciones de Terceros",
        p2l4:"Soporte Premium 24/7",
        p2l5:"Mantenimiento Frontend",
        p2l6:"SSL, SEO, SEM",

        plan3:"Webapp",
        p3:"Sitio Web con funcionalidades más robustas.",
        price3:"Contáctanos",
        p3l1:"Completamente Personalizada",
        p3l2:"Integraciones Robustas",
        p3l3:"Diseño Desde Cero",
        p3l4:"Soporte Personalizado 24/7",
        p3l5:"Curación de contenido",
        p3l6:"Diseño Funcional Espcífico",
        p3l7:"SSL, SEO, SEM",

        pricingT:"Elige el mejor",
        pricingH:"Producto para ti",
        pricingP:"Determina el producto que necesitas con base en el nivel de especialización",
    }
}