import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import navLinks from  "../text/Menu/Menu";
import { connect } from "react-redux";

class Pageheading extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <section className="page-title animatedbg gradient-bg2 text-center areacircles"  >
                <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1>{this.props.title}</h1>


                        </div>
                    </div>
                </div>
                <br/><br/><br/><br/>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(Pageheading);
