import React from "react"
import { Link } from "react-router-dom"
export const AboutUsText = {
    EN: {
        PTitle:"About Us",
       Title:"We've worked with",
        Highlight:"The best companies",
        p:"We've worked with companies of different sizes and sectors, from startups to large real estate development companies.  ",
        Subtitle:"Why Choose Us",
        titleFeature:"Our",
        FeatureHighlight:"Benefits",

        Q1: "What do we do?",
        A1: "We offer software development solutions. We focus on understanding our clients and their users needs. Then we devise solutions that take into account different variables, such as time and budget restrictions, exposure and scalability, user experience and user interface, and other factors to build the best products.",
        Q2: "Who is the team and their experience?",
        A2: "Our team of experts is trained to work with the best technology. Our multidisciplinary experience allows us to formulate" +
            "solutions that take into account the different areas of your business in a holistic way, generating solutions tailored to your needs." +
            "We analyze the technological infrastructure, processes and structures of your company to strengthen the short-term performance of the organization and maintain a long-term sustainable improvement.",
        Q3: "What clients / industries have we worked with in the past?",
        A3: "We have worked with clients from various industries, from e-learning and bots, to logistics and data analysis. The Point team" +
            "has the experience and knowledge to generate software solutions for any industry.",
        Q4: "If I have a development already done, do you need to start from scratch?",
        A4: "Depending on the development language used and its status, we could determine if it is necessary to start from scratch or if the existing development can be improved.",

    },
    ES: {
        PTitle:"Nosotros",
        Title:"Hemos trabajado con",
        Highlight:"las mejores empresas",
        p: "Hemos trabajado con empresas de distintos tamaños y sectores, desde startups hasta grandes empresas de desarrollo inmobiliario. ",
        Subtitle:"Por qué nosotros",
        titleFeature:"Nuestros",
        FeatureHighlight:"Beneficios",


        Q1:"¿Qué es lo que hacemos?",
        A1:"Ofrecemos soluciones de desarrollo de software. Nos enfocamos en comprender las necesidades de nuestros clientes y sus usuarios. De esta manera diseñamos soluciones que toman en cuenta diferentes variables, como restricciones de tiempo y presupuesto, exposición y escalabilidad, interfaz y experiencia del usuario, y otros factores para crear los mejores productos.",
        Q2:"¿Quién es el equipo y su experiencia?",
        A2:"Nuestro equipo de expertos está capacitado para trabajar con la mejor tecnología. Nuestra experiencia multidiciplinaria nos permite formular" +
            " soluciones que tomen en cuenta los distintos rubros de tu negocio de una forma holística, generando soluciones a la medida de tus necesidades." +
            " Analizamos la infraestructura tecnológica, los procesos y las estructuras de su empresa para fortalecer el desempeño a corto plazo de la organización y mantener una mejora sostenible a largo plazo.",
        Q3:"¿Con qué clientes/industrias trabajamos en el pasado?",
        A3:"Hemos trabajado con cientes de diversas industrias, desde e-learning y bots, hasta logística y análisis de datos. El equipo de Point " +
            "tiene la experiencia y el conocimiento para generar soluciones de software para cualquier industria.",
        Q4:"¿Si tengo un desarrollo ya hecho, necesitan empezar desde cero?",
        A4:"Dependiendo del lenguaje de desarrollo utilizado y el estado del mismo podríamos determinar si es necesario empezar de cero o si se puede mejorar el desarrollo existente.",

    },
}