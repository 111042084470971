import React from "react"
import { Link } from "react-router-dom"
export const FooterText = {
    EN: {
        Button:"Get In Touch",
        LeftFoot:"We know tech, Let us be your strategic ally and place you at the vanguard of " +
            " transformation. Maximize your efficiency through our technological tools and innovative strategies.",
        RightFoot:"Useful Links",
        Home:"Home",
        AboutUs:"AboutPoint",
        Blog:"Blog",
        Contacto:"Contact Us",
        PrivacyPolicy:"Privacy Policy",
        Rights:"All Rights Reserved",
    },
    ES: {
        Button:"Contáctanos",
        LeftFoot: "Sabemos de tecnología,déjanos ser tu aliado estratégico y colocarte a la vanguardia de la transformación digital.\n" +
            "Maximiza tu eficiencia a través de nuestras herramientas tecnológicas y estrategias innovadoras.",
        RightFoot:"Links Útiles",
        Home:"Home",
        AboutUs:"Sobre Nosotros",
        Blog:"Blog",
        Contacto:"Contacto",
        PrivacyPolicy:"Politica de Privacidad",
        Rights:"Todos los Derechos Reservados",
    },
}