import React, { Component } from 'react';
import Pageheading from '../../../widgets/Pageheading';

class BlogHeart extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
        return (
          <div>
          {/*page title start*/}
          <Pageheading foldername={"Blog"} title={"How Is Your Website’s H.E.A.R.T? Metrics In UCD"} />
          {/*page title end*/}
          {/*body content start*/}
          <div id="main-page" className="page-content">
            {/*blog start*/}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="post">
                      <div className="post-image">
                        <img className="img-fluid w-100" src={require(`../../../assets/images/blog/blog-heart.jpg`)} alt="" />
                      </div>
                      <div className="post-desc pl-0">
                        <div className="post-meta">
                          <ul className="list-inline">

                            <li>June 02, 2021</li>
                          </ul>
                        </div>
                        <div className="post-title">
                          <h2>How is your website’s H.E.A.R.T?
                            Metrics in UCD</h2>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p className="ml-5 text-black">Sometimes it’s good to suppose, but it’s always better to know.</p>
                        <p className="ml-5 text-black">When working in a development project with focus on the users, also called User Centered Designing (UCD), it is very important to have periodic checkups of the functionality of the website or app. You then need to measure how does the product is working in the real world, how it is fulfilling its purpose. That may sound like something difficult to do, because it’s very inefficient to watch or interview some users asking them about the product’s performance. Luckily there are some techniques that can give you very relevant information and very certain measures of your product with no need of asking users directly.</p>
                        <p className="ml-5 text-black">Every interaction we plan for the users in our site/App must have a specific goal. These goals may be focused on efficiency, effectiveness or satisfaction. For example, imagine working on a site for purchasing bus tickets. Some goals may be: “fast and easy walk-up-and-use user experience, with no user training at all”, or maybe “Low error rate for completing transactions correctly”. You can see where this is going.</p>
                        <p className="ml-5 text-black">Knowing how to measure the fulfilling of these goals may seem hard, but it’s actually very simple. You just need to know some key factors that will let you know how people explore your product. For example, measuring the time people take to complete a task, knowing the amount of “clicks” made in a certain page, getting a percentage of how many people return to your site, asking for user’s opinion, an so on…</p>
                      </div>
                      <br/>
                      
                      <div className="thumbnail-center-blog">
                        <img src={require(`../../../assets/images/blog/blog-heart-img1.png`)} alt=""/>
                      </div>

                      <br/>
                      <div>
                        <p className="ml-5 text-black">But having all these information is useless if you don’t know how to read it. For doing this, I highly recommend google’s HEART framework. HEART is a very visual way for selecting the right UX metrics and organize them in a simple way. It consists of a table with some general goals and how each of them is measured. On each row you have these general goals: Happiness, Engagement, Adoption, Retention and Task Success. Happiness measures user attitudes, often collected via survey, like satisfaction, perceived ease of use, etc. Engagement refers to the level of user involvement, like number of visits per month, number of likes, number of uploads, etc. Adoption is how much does a user chooses your page as his #1 option, measuring things like upgrades, subscriptions or number of purchases. Retention is the rate at which existing users are returning, measuring thing like active users present over time or a renewal rate. And finally, Task Success means the efficiency, effectiveness, and error rate, knowing it by time to complete a task, number of completed tasks, etc. Then, on the columns, you write the specific goals, the signals and the metrics. The goals section include what do you want your users to achieve or get from your site that will generate you some value. The signals are how will you map the goals at lower level. And in the metrics section you refine those signals into metric you track over time.</p>
                        <p className="ml-5 text-black">Know getting all these information without asking or watching the users seems a little unreal. But actually you can do it. The most simple way for this is with a Google tool called “Google Analytics”. This is a wonderful tool that provides just this metrics. All you have to do is select which of them do you want to know.</p>
                        <p className="ml-5 text-black">So, that’s it! Now you can take your website or app to an appointment and getting knowledge of its H.E.A.R.T condition. Do it, you will not regret it.</p>
                      </div>

                    </div>
                    
                    {/*Other Blogs Start*/}
                    {/* <div className="p-4 my-5 box-shadow" style={{backgroundColor: 'rgba(244,77,133,0.050)' }} >
                                        <RelatedBlog logoitems={2} />
                                    </div>*/}
                    {/*Other Blogs End*/}
                    
                  </div>
                </div>
              </div>
            </section>
            {/*blog end*/}
          </div>
          {/*body content end*/}
        </div>
      );
    }
}

export default BlogHeart;