import React from "react"
import { Link } from "react-router-dom"
export const FAQDevelopmentPlansText = {
    EN: {


        Q1:"What can you help me with?",
        A1:"We can assist you with creating user personas, mock-ups, user stories, time frame, establishing project status" +
            " and preparing project estimation. We'll be happy to outline project specification, prepare wireframes, details" +
            " concerning integrations with external services, API documentation, required features list.\n" +
            "\n" +
            "In terms of project development we prepare server-side architecture, front end, back end, graphic and UX design," +
            " and create back-office consoles.",
        Q2:"Should I create a mobile or a web app?",
        A2:"Both have their benefits and flaws. Mobile apps are surely more expensive but can provide you with many more " +
            "data collecting, monetisation capabilities than web applications. Progressive Web Apps are a good compromise between a mobile and web app.",
        Q3:"How much time will it take for you to make my app?",
        A3:"To give you a precise answer we must prepare a project scope and create a budget. We shall tell you how much " +
            "of that scope can be completed within the budget and we'll be able to estimate how long will it take after a few days of work.",
        Q4:"Do I need to be tech-savvy to cooperate with you and order a software application?",
        A4:"You certainly do not have to be a software development expert, that's what we're here for. We welcome your" +
            " experience in that matter if you happen to have any, but are prepared to guide you through the whole process" +
            "and explain everything. Focus on your needs and we'll deliver what you require.",
        Q5:"I want to be involved in the app development process, do you have a problem with that?",
        A5:"Not at all! We want you to be sure you'll receive the product you desire. From the planning stage to each finished " +
            "version, we invite you to evaluate and improve our work. Our philosophy is to work with the client, not merely finish given tasks. " +
            "We are always ready to listen and communicate.",
        Q6:"Can the software be upgraded in the future as new technology becomes available?",
        A6:"Of course! We are always happy to improve, upgrade and further develop your work, or the work we had done with you.",

    },
    ES: {


        Q1:"¿Con qué me pueden ayudar?",
        A1:"Podemos ayudarlo a crear user personas, mock-ups, historias de usuario, marcos de tiempo, establecer el tiempo del proyecto y " +
            "preparar la estimación del proyecto. Estaremos encantados de delinear la especificación del proyecto, preparar wireframes, detalles relacionados " +
            "con integraciones con servicios externos, documentación de API , lista de características requeridas. En términos de desarrollo de proyectos, " +
            "preparamos arquitectura del lado del servidor, front-end, back-end, diseño gráfico y UX, y creamos consolas back-office.",
        Q2:"¿Debo desarrollar una aplicación web o móvil?",
        A2:"Ambas tienen sus ventajas y sus defectos. Las aplicaciones móviles son seguramente más caras, pero pueden proporcionarle muchas más" +
            " capacidades de obtención de datos y monetización que las aplicaciones web. Las aplicaciones web progresivas son un buen " +
            "compromiso entre una aplicación móvil y una aplicación web.",
        Q3:"¿Cuánto tiempo tomará desarrollar mi aplicación?",
        A3:"Para darle una respuesta precisa, debemos preparar un alcance del proyecto y crear un presupuesto. Le diremos cuánto de ese alcance" +
            " se puede completar dentro del presupuesto y podremos estimar cuánto tiempo tomará después de unos días de trabajo.",
        Q4:"¿Necesito ser experto en tecnología para cooperar con usted y solicitar una aplicación de software?",
        A4:"Ciertamente no tiene que ser un experto en desarrollo de software, para eso estamos aquí. Agradecemos su experiencia en ese asunto" +
            " si tiene alguna, pero estamos preparados para guiarlo a través de todo el proceso y explicarlo todo. Enfoque según sus necesidades" +
            " y le entregaremos lo que necesite.",
        Q5:"Quiero participar en el proceso de desarrollo de la aplicación, ¿tienen algún problema con eso?",
        A5:"¡Para nada! Queremos que esté seguro de que recibirá el producto que desea. Desde la etapa de planificación hasta cada versión terminada, " +
            "lo invitamos a evaluar y mejorar nuestro trabajo. Nuestra filosofía es trabajar con el cliente, no solo terminar las tareas asignadas. " +
            "Siempre estamos dispuestos a escuchar y comunicarnos.",
        Q6:"¿Se puede actualizar el software en el futuro a medida que se disponga de nueva tecnología?",
        A6:"¡Por supuesto! Siempre estamos felices de mejorar, actualizar y desarrollar aún más su trabajo, o el trabajo que hemos hecho con usted.",
    },
}