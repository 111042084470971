import React, { Component } from 'react';

import Pageheading from '../../../widgets/Pageheading';

class BlogScrum extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                {/*page title start*/}
                <Pageheading foldername={"Blog"} title={"Ready, set, SCRUM!"} />
                {/*page title end*/}
                {/*body content start*/}
                <div id="main-page" className="page-content">
                    {/*blog start*/}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="post">
                                        <div className="post-image">
                                            <img className="img-fluid w-100" src={require(`../../../assets/images/blog/blog-scrum.jpg`)} alt="" />
                                        </div>
                                        <div className="post-desc pl-0">
                                            <div className="post-meta">
                                                <ul className="list-inline">

                                                    <li>May 12, 2021</li>
                                                </ul>
                                            </div>
                                            <div className="post-title">
                                                <h2>Ready, set, SCRUM!</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <p className="ml-5 text-black">Developing a web page, an app, hardware or software can be a pretty tedious and complicated task. Fortunately, different frameworks and methodologies have been designed in order to optimize this process and modulate the task appropriately. The name of this awesome technique is SCRUM.</p>
                                            <p className="ml-5 text-black">What is SCRUM?</p>
                                            <p className="ml-5 text-black">SCRUM is an agile framework that allows developing teams to work in a faster and more flexible way. In order to implement SCRUM, you first need to understand the three roles that this methodology involves: Product Owner (PO), Scrum Master (SM) and Development Team.</p>
                                            <p className="ml-5 text-black">The Product Owner is the voice of the customer. He is the one in charge of giving the project its direction and defining the criteria for acceptance. The PO, together with the Scrum Master, should divide the requirements into features and organize them hierarchically in the product backlog.</p>
                                            <p className="ml-5 text-black">The Scrum Master is the person in charge of keeping the PO and the Dev Team in the same page. The SM can be considered as the leader in the scrum, and he must assure that the SCRUM framework is being executed correctly, taking care of not forgetting any SCRUM activity during the development.</p>
                                            <p className="ml-5 text-black">Finally, the Development Team are the people responsible of executing the job. Their job is to meet the criteria set by the PO and to give feedback in order to make each iteration better. Within the Dev Team, the integrant are self-organized, any external interaction is usually made throughout the SM.</p>

                                        </div>
                                        <br/>

                                        <div className="thumbnail-center-blog">
                                            <img src={require(`../../../assets/images/blog/blog-scrum.png`)} alt=""/>
                                        </div>

                                        <br/>
                                        <div>
                                            <p className="ml-5 text-black">How to execute SCRUM?</p>
                                            <p className="ml-5 text-black">In order to implement SCRUM into product development, you must understand the different activities it involves:</p>
                                            <p className="ml-5 text-black">1. Product backlog- this consists in braking down the project into measurable, individual tasks. These are features chosen by the PO and are organized hierarchically.</p>
                                            <p className="ml-5 text-black">2. Sprint- sprints are the fundamental activity of SCRUM. They consist in producing one or more features from the backlog in a period of one to four weeks. Sprints must be measurable.</p>
                                            <p className="ml-5 text-black">3. Sprint planning- during this period, the team has to estimate the time it will take to execute the sprint, what features are going to be completed and clarifying any questions of the team.</p>
                                            <p className="ml-5 text-black">4. Daily scrum- this is a 15-minute daily meeting, in which the Dev Team asks themselves: what did I do yesterday? What am I doing today? And what is stopping me?</p>
                                            <p className="ml-5 text-black">5. Sprint review- the point of this meeting is to inspect what you have done, and adapt in order to make the next sprint better. The team should ask themselves: what worked out well? What worked out badly? And what could improve next time?</p>
                                            <p className="ml-5 text-black">As any developer must know, making a piece of software or a web page from scratch consumes a huge amount of time, therefore it’s important to have knowledge of methodologies that help you get the job faster and with better quality. Implementing SCRUM into the development of any project can and will be useful and will help you to make twice the job in half the time.</p>

                                        </div>

                                    </div>


                                    {/*Other Blogs Start*/}
                                    {/* <div className="p-4 my-5 box-shadow" style={{backgroundColor: 'rgba(244,77,133,0.050)' }} >
                                        <RelatedBlog logoitems={2} />
                                    </div>*/}
                                    {/*Other Blogs End*/}

                                </div>
                            </div>
                        </div>
                    </section>
                    {/*blog end*/}
                </div>
                {/*body content end*/}
            </div>
        );
    }
}

export default BlogScrum;