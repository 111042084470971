import React, { Component } from 'react';
import CountUp from 'react-countup';

import { connect } from "react-redux";
import  {WebAppsHeroT} from "../../text/Servicios/AppsWeb/WebAppsHeroT";


class WebAppsHero extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (


            <div className="row justify-content-center text-center" style={{ "padding-top":"25px"}}>
                <br/>
                <div className="col-lg-7 col-md-12">

                    <div className="section-title">
                        <h6>{WebAppsHeroT[localStorage.getItem("language")].Subtitle}</h6>
                        <h2 className="title">{WebAppsHeroT[localStorage.getItem("language")].Title} <span>{WebAppsHeroT[localStorage.getItem("language")].Highlight} </span></h2>
                        <p className="lead mb-0">{WebAppsHeroT[localStorage.getItem("language")].p}</p>
                        <br />
                        <p className="lead mb-0">{WebAppsHeroT[localStorage.getItem("language")].p2}</p>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(WebAppsHero);