import React, { Component } from 'react';
import {connect} from "react-redux";
import {AboutUsText} from "../../../text/Nosotros/AboutUsText";
import {FAQDevelopmentPlansText} from "../../../text/Servicios/Software/FAQDevelopmentPlansT"




class FAQDeveleopment  extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (

            <div className="row">
                <div className="col-md-6 col-12">
                    <div className="accordion">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mb-0">
                                    {FAQDevelopmentPlansText[localStorage.getItem("language")].Q1}
                                </h6>
                            </div>
                            <div className="card-body">{FAQDevelopmentPlansText[localStorage.getItem("language")].A1}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-md-0 mt-5">
                    <div className="accordion">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mb-0">
                                    {FAQDevelopmentPlansText[localStorage.getItem("language")].Q2}
                                </h6>
                            </div>
                            <div className="card-body">{FAQDevelopmentPlansText[localStorage.getItem("language")].A2}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-5">
                    <div className="accordion">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mb-0">
                                    {FAQDevelopmentPlansText[localStorage.getItem("language")].Q3}
                                </h6>
                            </div>
                            <div className="card-body">{FAQDevelopmentPlansText[localStorage.getItem("language")].A3}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-5">
                    <div className="accordion">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mb-0">
                                    {FAQDevelopmentPlansText[localStorage.getItem("language")].Q4}
                                </h6>
                            </div>
                            <div className="card-body">{FAQDevelopmentPlansText[localStorage.getItem("language")].A4}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-5">
                    <div className="accordion">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mb-0">
                                    {FAQDevelopmentPlansText[localStorage.getItem("language")].Q5}
                                </h6>
                            </div>
                            <div className="card-body">{FAQDevelopmentPlansText[localStorage.getItem("language")].A5}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-5">
                    <div className="accordion">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="mb-0">
                                    {FAQDevelopmentPlansText[localStorage.getItem("language")].Q6}
                                </h6>
                            </div>
                            <div className="card-body">{FAQDevelopmentPlansText[localStorage.getItem("language")].A6}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(FAQDeveleopment);
