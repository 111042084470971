import React, { Component } from 'react';


import { connect } from "react-redux";

import FeatureBox5 from "../../widgets/featurebox/FeatureBox5";
import HerosectionAnimatedGradient from "../../widgets/herosecation/HerosectionAnimatedGradient";
import MobileAppHero from "../../widgets/herotexts/mobileappshero";
import {HeroSect4T} from "../../text/Servicios/AppsMovil/HeroSect4T";
import Cuestionario from "../../widgets/cuestionario";

class AppsMovil extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: 1
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleMenuHover(itemno) {
    this.setState({
      isHovered: itemno
    });
  }
  render() {
    const {isHovered} = this.state;
    return (
      <div>
        {/*hero section start*/}
        <HerosectionAnimatedGradient />
        {/*hero section end*/}

        <MobileAppHero />


        {/*body content start*/}
        <div id="main-page" className="page-content">
          <section className=" p-0"  style={{ backgroundColor:"#ffffff"}}   >
            <div className="container">
              <div className="row custom-mt-10">



                {/*service start*/}
                <section className="custom-mt-10 pt-0 text-center">
                  <div className="container">
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <FeatureBox5 />
                  </div>
                </section>
                {/*service end*/}



                  {/* <PortfolioMasonryStyle /> */}



              </div>
            </div>
          </section>
          {/*counter start*/}
          {/*section className="theme-bg">
            <div className="container">
              <Counter3 />
            </div>
          </section>
          {/*counter end*/}
        </div>

        <Cuestionario />
        {/*body content end*/}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { language: state.language };
};

export default connect(mapStateToProps)(AppsMovil);