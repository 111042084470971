import React, { Component } from 'react';

import { connect } from "react-redux";
import  {SkillBoxT} from "../../text/Servicios/Software/SkillBoxT";
import {IndexText} from "../../text/Index/IndexText";

class SkillBox1 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="featured-item white-bg">
                        <div className="featured-icon center-icon " style={{color:"#9500FFFF"}}> <i className="flaticon-idea" />
                        </div>
                        <div className="featured-title">
                            <h5 className="mb-0">{SkillBoxT[localStorage.getItem("language")].s1}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mt-5 mt-sm-0">
                    <div className="featured-item white-bg">
                        <div className="featured-icon center-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-domain" />
                        </div>
                        <div className="featured-title">
                            <h5 className="mb-0">{SkillBoxT[localStorage.getItem("language")].s2}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mt-5 mt-lg-0">
                    <div className="featured-item white-bg">
                        <div className="featured-icon center-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-analytics" />
                        </div>
                        <div className="featured-title">
                            <h5 className="mb-0">{SkillBoxT[localStorage.getItem("language")].s3}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mt-5 mt-lg-0">
                    <div className="featured-item white-bg">
                        <div className="featured-icon center-icon" style={{color:"#9500FFFF"}}> <i className="flaticon-optimization" />
                        </div>
                        <div className="featured-title">
                            <h5 className="mb-0">{SkillBoxT[localStorage.getItem("language")].s4}</h5>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}
const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(SkillBox1);

