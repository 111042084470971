import React, { Component } from 'react';
import ParticlesBg from '../PraticlesBg';
import { connect } from "react-redux";
import {HeroSection2Text} from "../../text/Servicios/AppsWeb/HeroSection2Text.js";

class Herosection2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <section className="banner-2 pos-r ">
          <ParticlesBg />
          <div className="container" >
            <div className="row align-items-center" >
              <div className="col" >
                <h5 className="d-inline-block text-theme px-3 py-2 white-bg box-shadow" style={{marginTop:"100px"}}>
                    {HeroSection2Text[localStorage.getItem("language")].subtitle}
                </h5>
                <h1 className="bnr-title2 mb-0 mt-3">{HeroSection2Text[localStorage.getItem("language")].title}</h1>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

const mapStateToProps = state => {
    return { language: state.language };
};

export default connect(mapStateToProps)(Herosection2);