import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ParticlesBg from '../PraticlesBg';

import {connect} from "react-redux";
import {HeroSect4T} from "../../text/Servicios/AppsMovil/HeroSect4T";
import Contact from "../../text/Menu/Contact";


class HerosectionAnimatedGradient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animationClass: 'test'
        }

    }



    render() {
        return (
            <section className="banner pos-r fullscreen-banner animated-gradient-bg o-hidden heroanimationappmov" >

                <div className="align-center pt-0 b-pl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-5 offset-lg-1 offset-md-1">
                                <h1 className="text-black">{HeroSect4T[localStorage.getItem("language")].t2}</h1>
                            </div>

                            <div className="col-lg-5 col-md-5 ">
                                <a className="btn btn-lg btn-outline-light ml-3 mr-lg-5" href={HeroSect4T[localStorage.getItem("language")].typeform} target="_blank" to={HeroSect4T[localStorage.getItem("language")].path} > {HeroSect4T[localStorage.getItem("language")].buttonmovil} </a>
                            </div>

                        </div>
                    </div>

                </div>


            </section>

        );
    }
}

const mapStateToProps = state => {
    return {language: state.language};
};

export default connect(mapStateToProps)(HerosectionAnimatedGradient);