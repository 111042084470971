import React from "react"
import { Link } from "react-router-dom"
export const CrossplatformT = {
    EN: {
        title1: "Your business presentation letter",
        title2:"We develop",
        highlight:"Quality Websites",
        p:"Develop a webiste that fits with your company's image and values. Save time with one of a thousand templates or design your own site pixel by pixel.",
        button:"Get in touch",
        point:"Point",
        p1:"uses the best technologies to allow your team or clients to access your platform without worrying about the device.",
        AboutTitle:"We're experts in multiple areas",
        win1:"UX/UI",
        win2:"Frontend",
        win3:"Backend",
        win4:"Integration with other technologies",
        desc:"With point you obtain the expertise needed to integrate the best technologies in a single multidiciplinary solution.",
        aplications:"Applications by industry",
        descriptapps:"Cross-platform software has many different applications in various industries. Explore new ways to improve your business efficiency.",
        s1:"Web Development",
        sd1:"We build interfaces that can be accessed online without worrying of the device being used.",
        s2:"Analytics",
        sd2:"",
        s3:"Design",
        sd3:"",
        s4:"Strategy",
        sd4:"",
        s5:"Marketing",
        sd5:"",
        typeform:"https://unuw5x9zxht.typeform.com/to/cPnVqj46",
    },
    ES: {
        title1: "La carta de presentación de tu negocio",
        title2:"Desarrollamos",
        highlight:"Sitios web de calidad",
        p:"Con nuestras soluciones multiplataforma la interfaz no será un problema. Ponte en contacto para optimizar tus procesos más importantes.",
        button:"Ponte en contacto",
        point:"Point",
        p1:"utiliza las mejores tecnologías para permitir a tu equipo o clientes acceder a tu plataforma sin preocuparse por el dispositivo.",
        AboutTitle:"Somos expertos en múltiples áreas.",
        win1:"UX/UI",
        win2:"Frontend",
        win3:"Backend",
        win4:"Integración con otras tecnologías",
        desc:"Con point obtienes la experiencia necesaria para integrar las mejores tecnologías en una única solución multidisciplinaria.",
        aplications:"Aplicaciones por industria",
        descriptapps:"El software multiplataforma tiene muchas aplicaciones diferentes en diversas industrias. Explore nuevas formas de mejorar la eficiencia de su empresa.",
        s1:"Desarrollo Web",
        sd1:"Creamos interfaces a las que se puede acceder en línea sin preocuparse por el dispositivo que se está utilizando.",
        s2:"Analíticas",
        sd2:"",
        s3:"Diseño",
        sd3:"",
        s4:"Estrategia",
        sd4:"",
        s5:"Marketing",
        sd5:"",
        typeform:"https://unuw5x9zxht.typeform.com/to/LYgj7sVX",
    },
}